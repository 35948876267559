import produce from "immer";

export function switchTheme() {
  return {
    type: "SWITCH_THEME",
  };
}

const hour = new Date().getHours();
const mode = hour < 6 || hour >= 18 ? "dark" : "light";

const initialState = {
  palette: {
    mode: mode,
    primary: {
      main: "#3d993d",
      contrastText: "#ffffffe6",
    },
    secondary: {
      main: "#764a0d",
      contrastText: "#ffffffe6",
    },
    error: {
      main: "#d04444",
    },
    brown: {
      main: "#aa7021",
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          boxShadow: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiFab: {
      styleOverrides: { root: { boxShadow: "none" } },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fieldset: {
            border: "var(--card-border)",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        },
      },
    },
  },
};

const themeConfigReducer = produce((themeConfig = initialState, action) => {
  switch (action.type) {
    case "SWITCH_THEME":
      themeConfig.palette.mode =
        themeConfig.palette.mode === "light" ? "dark" : "light";
      break;
    default:
      return themeConfig;
  }
});

export default themeConfigReducer;
