import ContactsIcon from "@mui/icons-material/Contacts";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StarsIcon from "@mui/icons-material/Stars";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonalInfo from "../pages/Editor/PersonalInfo/PersonalInfo";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Education from "../pages/Editor/Education/Education";
import Skills from "../pages/Editor/Skills/Skills";
import Experience from "../pages/Editor/Experience/Experience";
import Projects from "../pages/Editor/Projects/Projects";
import Hobbies from "../pages/Editor/Hobbies/Hobbies";
import PersonalInfoTips from "./markdown/Tips/PersonalInfo.md";
import EducationTips from "./markdown/Tips/Education.md";
import ProjectsTips from "./markdown/Tips/Projects.md";
import SkillsTips from "./markdown/Tips/Skills.md";
import ExperienceTips from "./markdown/Tips/Experience.md";
import HobbiesTips from "./markdown/Tips/Hobbies.md";
import CertificatesTips from "./markdown/Tips/Certificates.md";
import Certificates from "../pages/Editor/Certificates/Certificates";

const sectionData = [
  {
    id: "p_info",
    title: "Personal Info",
    icon: <ContactsIcon />,
    component: <PersonalInfo />,
    tips: PersonalInfoTips,
  },
  {
    id: "edu",
    title: "Education",
    icon: <SchoolIcon />,
    component: <Education />,
    tips: EducationTips,
  },
  {
    id: "ski",
    title: "Skills",
    icon: <WidgetsIcon />,
    component: <Skills />,
    tips: SkillsTips,
  },
  {
    id: "exp",
    title: "Experience",
    icon: <WorkIcon />,
    component: <Experience />,
    tips: ExperienceTips,
  },
  {
    id: "prj",
    title: "Projects",
    icon: <StarsIcon />,
    component: <Projects />,
    tips: ProjectsTips,
  },
  {
    id: "cer",
    title: "Certificates",
    icon: <WorkspacePremiumIcon />,
    component: <Certificates />,
    tips: CertificatesTips,
  },
  {
    id: "hb",
    title: "Hobbies",
    icon: <FavoriteIcon />,
    component: <Hobbies />,
    tips: HobbiesTips,
  },
];

export default sectionData;
