import React, { useEffect, useState } from "react";
import "./Tips.less";
import { useParams } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import sectionData from "../../data/SectionData";

const Tips = () => {
  const { sectionId } = useParams();
  const section = sectionData.find((item) => item.id === sectionId);

  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(section?.tips)
      .then((res) => res.text())
      .then((data) => setMarkdown(data));
  });

  return (
    <div id="cv__tips" className="cv__card">
      <div className="cv__tips-title">Tips</div>
      <div className="cv__tips-content cv__scrollbar">
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Tips;
