import React from "react";
import "./TemplateDetail.less";
import DialogPro from "../DialogPro/DialogPro";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const TemplateDetail = ({ isZoomIn, setIsZoomIn, crtTemp, toBuilderPage }) => {
  return (
    <DialogPro
      className="cv__dialogpro-tempdetails"
      isShowDialog={isZoomIn}
      onClose={() => setIsZoomIn(false)}
    >
      <div className="cv__dialogpro-left">
        <img
          src={
            crtTemp?.img
              ? crtTemp?.img
              : "https://marketplace.canva.com/EAFEghuoNPY/1/0/1131w/canva-cream-pink-minimalist-receptionist-cv-resume-sbfF6WUiDig.jpg"
          }
          alt="Current Template"
        />
      </div>
      <div className="cv__dialogpro-right">
        <h2>{crtTemp?.title}</h2>
        <div className="cv__dialogpro-author">
          <AccountCircleIcon />
          <Link to={`mailto:${crtTemp?.email}`} className="cv__link-text">
            {crtTemp?.author}
          </Link>
        </div>
        <p className="cv__dialogpro-desc">「 {crtTemp?.desc} 」</p>
        {toBuilderPage && (
          <Button
            onClick={() => toBuilderPage(crtTemp?.template)}
            startIcon={<ArrowCircleRightIcon />}
            variant="contained"
          >
            Use this template
          </Button>
        )}
      </div>
    </DialogPro>
  );
};

export default TemplateDetail;
