import React from "react";
import "./Home.less";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import TemplateCards from "../../components/TemplateCards/TemplateCards";
import templateData from "../../data/TemplatesData";

const Hero = () => {
  const navigate = useNavigate();

  const TemplateCards = ({ data }) => (
    <>
      {data.map((temp) => (
        <div key={temp.template.id} className="cv__home-hero-img-box">
          <img src={temp.img} alt="CV cover" />
        </div>
      ))}
    </>
  );

  return (
    <section className="cv__home-hero">
      <div className="cv__home-hero-content">
        <h1 className="animate__animated animate__fadeInDown">
          Create an impressive CV efficiently
        </h1>
        <h1 className="animate__animated animate__fadeInDown animate__delay-1s">
          with stunning templates
        </h1>
        <h1 className="animate__animated animate__fadeInDown animate__delay-2s">
          and ChatGPT
        </h1>
        <Button
          className="animate__animated animate__fadeInDown animate__delay-3s"
          style={{ marginTop: "30px" }}
          variant="contained"
          size="large"
          onClick={() => navigate("/start")}
        >
          Let's Get Started!
        </Button>
      </div>

      <div
        className="cv__home-hero-img-groups"
        title="Check out CV Templates"
        onClick={() => navigate("/templates")}
      >
        <div className="cv__home-hero-img-group">
          <TemplateCards data={templateData} />
          <TemplateCards data={templateData} />
        </div>

        <div className="cv__home-hero-img-group">
          <TemplateCards data={templateData.toReversed()} />
          <TemplateCards data={templateData.toReversed()} />
        </div>
      </div>
    </section>
  );
};

const Section = (props) => {
  return (
    <section className={`cv__home-section cv__home-${props.className}`}>
      <h1 className="cv__home-title">{props.title}</h1>
      {props.children}
    </section>
  );
};

const Home = () => {
  const navigate = useNavigate();
  return (
    <div id="cv__home">
      <Hero />

      <div className="cv__home-main">
        <Section className="template" title="CV Templates">
          <TemplateCards isHome={true} />
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/templates")}
          >
            Find Out More
          </Button>
        </Section>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
