import { produce } from "immer";

export const onTemplateChange = (template) => {
  return {
    type: "ON_TEMPLATE_CHANGE",
    payload: template,
  };
};

export const onTemplateKeyChange = (name, value) => {
  return {
    type: "ON_TEMPLATE_KEY_CHANGE",
    payload: {
      name,
      value,
    },
  };
};

export const onFormChange = (section, name, value) => {
  return {
    type: "ON_FORM_CHANGE",
    payload: {
      section,
      name,
      value,
    },
  };
};

export const onFormReplace = (section, value) => {
  return {
    type: "ON_FORM_REPLACE",
    payload: {
      section,
      value,
    },
  };
};

export const onFormItemChange = (section, index, name, value) => {
  return {
    type: "ON_FORM_ITEM_CHANGE",
    payload: {
      section,
      index,
      name,
      value,
    },
  };
};

export const onFormItemReplace = (section, itemIndex, name, value) => {
  return {
    type: "ON_FORM_ITEM_REPLACE",
    payload: {
      section,
      itemIndex,
      name,
      value,
    },
  };
};

export const onFormItemArrayChange = (section, index, value) => {
  return {
    type: "ON_FORM_ITEM_ARRAY_CHANGE",
    payload: {
      section,
      index,
      value,
    },
  };
};

export const onFormItemObjectChange = (
  section,
  itemIndex,
  arrayIndex,
  name,
  value
) => {
  return {
    type: "ON_FORM_ITEM_OBJECT_CHANGE",
    payload: {
      section,
      itemIndex,
      arrayIndex,
      name,
      value,
    },
  };
};

export const onFormItemEnable = (section) => {
  return {
    type: "ON_FORM_ITEM_ENABLE",
    payload: section,
  };
};

export const addFormItem = (section) => {
  return {
    type: "ADD_FORM_ITEM",
    payload: section,
  };
};

export const deleteFormItem = (section, itemIndex) => {
  return {
    type: "DELETE_FORM_ITEM",
    payload: {
      section: section,
      itemIndex: itemIndex,
    },
  };
};

export const addFormItemArray = (section, name, itemIndex) => {
  return {
    type: "ADD_FORM_ITEM_ARRAY",
    payload: {
      section,
      name,
      itemIndex,
    },
  };
};

export const deleteFormItemArray = (section, name, itemIndex, arrayIndex) => {
  return {
    type: "DELETE_FORM_ITEM_ARRAY",
    payload: {
      section,
      name,
      itemIndex,
      arrayIndex,
    },
  };
};

export const uploadCVData = (cvData) => {
  return {
    type: "UPLOAD_FORM_DATA",
    payload: cvData,
  };
};

export const onFormGeneration = (newCVData) => {
  return {
    type: "ON_FORM_GENERATION",
    payload: newCVData,
  };
};

const initialState = {
  template: {
    id: "",
    font_size_title: 22,
    font_size_text: 14,
    font_family_title: "",
    font_family_text: "",
    theme_color: "#000000",
  },
  orders: ["edu", "ski", "exp", "prj", "cer", "hb"],
  p_info: {
    photo: "",
    title: "Personal Info",
    name: "",
    job_title: "",
    location: "",
    phone_num: "",
    email: "",
    url: "",
    github: "",
    intro: "",
  },
  edu: {
    title: "Education",
    item: [
      {
        school: "",
        degree: "",
        major: "",
        location: "",
        start_date: "",
        end_date: "",
        desc: [""],
      },
    ],
  },
  ski: {
    title: "Skills",
    item: [
      {
        ski_category: "",
        skills: [""],
      },
    ],
  },
  exp: {
    title: "Experience",
    enable: false,
    item: [
      {
        position: "",
        company: "",
        start_date: "",
        end_date: "",
        location: "",
        desc: [""],
      },
    ],
  },
  prj: {
    title: "Projects",
    enable: false,
    item: [
      {
        prj_name: "",
        skills: "",
        start_date: "",
        end_date: "",
        desc: [""],
      },
    ],
  },
  cer: {
    title: "Certificates",
    enable: false,
    item: [""],
  },
  hb: {
    title: "Hobbies",
    enable: false,
    item: [
      {
        hb_category: "",
        hobbies: [""],
      },
    ],
  },
};

const cvDataReducer = produce((cvData = initialState, action) => {
  let section, index, name, value, itemIndex, arrayIndex;
  switch (action.type) {
    case "ON_TEMPLATE_CHANGE":
      cvData.template = action.payload;
      break;
    case "ON_TEMPLATE_KEY_CHANGE":
      ({ name, value } = action.payload);
      cvData.template[name] = value;
      break;
    case "ON_FORM_CHANGE":
      ({ section, name, value } = action.payload);
      cvData[section][name] = value;
      break;
    case "ON_FORM_REPLACE":
      ({ section, value } = action.payload);
      cvData[section] = value;
      break;
    case "ON_FORM_ITEM_CHANGE":
      ({ section, index, name, value } = action.payload);
      cvData[section].item[index][name] = value;
      break;
    case "ON_FORM_ITEM_REPLACE":
      ({ section, itemIndex, name, value } = action.payload);
      cvData[section].item[itemIndex][name] = value;
      break;
    case "ON_FORM_ITEM_ARRAY_CHANGE":
      ({ section, index, value } = action.payload);
      cvData[section].item[index] = value;
      break;
    case "ON_FORM_ITEM_OBJECT_CHANGE":
      ({ section, itemIndex, arrayIndex, name, value } = action.payload);
      cvData[section].item[itemIndex][name][arrayIndex] = value;
      break;
    case "ON_FORM_ITEM_ENABLE":
      section = action.payload;
      cvData[section].enable = !cvData[section].enable;
      break;
    case "ADD_FORM_ITEM":
      section = action.payload;
      // Copy the first data item and clear all values. Note that the expand syntax is used here to avoid them sharing the same address.
      var newItem;
      if (typeof cvData[section].item[0] === "object") {
        newItem = { ...cvData[section].item[0] };
        Object.keys(newItem).forEach((key) => {
          // Need to determine if it's an Array or a String
          newItem[key] = Array.isArray(newItem[key]) ? [""] : "";
        });
      } else {
        newItem = "";
      }
      cvData[section].item.push(newItem);
      break;
    case "DELETE_FORM_ITEM":
      ({ section, itemIndex } = action.payload);
      cvData[section].item.splice(itemIndex, 1);
      break;
    case "ADD_FORM_ITEM_ARRAY":
      ({ section, name, itemIndex } = action.payload);
      cvData[section].item[itemIndex][name].push("");
      break;
    case "DELETE_FORM_ITEM_ARRAY":
      ({ section, name, itemIndex, arrayIndex } = action.payload);
      cvData[section].item[itemIndex][name].splice(arrayIndex, 1);
      break;
    case "UPLOAD_FORM_DATA":
      return action.payload;
    case "ON_FORM_GENERATION":
      return { ...cvData, ...action.payload };
    default:
      return cvData;
  }
});

export default cvDataReducer;
