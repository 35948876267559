export function createSnack(obj) {
  return {
    type: "CREATE_SNACK",
    payload: obj,
  };
}

export function closeSnack() {
  return {
    type: "CLOSE_SNACK",
  };
}

const initData = {
  open: false,
  duration: 3000,
  severity: "",
  desc: "",
};

const snackProReducer = (snackData = initData, action) => {
  switch (action.type) {
    case "CREATE_SNACK":
      return { ...snackData, ...action.payload, open: true };
    case "CLOSE_SNACK":
      return { ...snackData, open: false, duration: 3000 };
    default:
      return snackData;
  }
};

export default snackProReducer;
