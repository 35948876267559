import React, { useEffect, useState } from "react";
import "./DialogPro.less";
import { useDispatch } from "react-redux";
import { hideDialog } from "../../redux/HandleDialog";

const DialogPro = ({
  children,
  className,
  isShowDialog,
  onClose,
  extraFunc,
}) => {
  const [isHiding, setIsHiding] = useState(false);
  const dispatch = useDispatch();

  // Close dialog box (with animation)
  const hidePreview = () => {
    setIsHiding(true);
    const timer = setTimeout(() => {
      // If onClose is undefined, it is a global control switch; if defined, it is a local control switch;
      if (onClose) {
        onClose();
      } else {
        dispatch(hideDialog());
      }
      setIsHiding(false);
      clearTimeout(timer);
    }, 300);
  };

  // Press ESC to close the dialog box
  useEffect(() => {
    // Code that needs to be run extra
    if (extraFunc) {
      extraFunc();
    }

    const handleKeyDown = (event) => {
      if (isShowDialog && event.key === "Escape") {
        hidePreview();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <>
      {isShowDialog && (
        <>
          <div
            className={`cv__backdrop${isHiding ? "" : " fadeIn"}`}
            onClick={hidePreview}
          ></div>
          <div
            className={`cv__dialogpro cv__card${
              className ? " " + className : ""
            }${isHiding ? "" : " zoomIn"}`}
          >
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default DialogPro;
