export function showDialog() {
  return {
    type: "SHOW_DIALOG",
  };
}

export function hideDialog() {
  return {
    type: "HIDE_DIALOG",
  };
}

export default function handleDialogReducer(dialogState = false, action) {
  switch (action.type) {
    case "SHOW_DIALOG":
      return true;
    case "HIDE_DIALOG":
      return false;
    default:
      return dialogState;
  }
}
