import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.less";
import { useGoogleLogin } from "@react-oauth/google";
import { Alert, Snackbar, TextField, Button } from "@mui/material";
import { produce } from "immer";
import { useDispatch } from "react-redux";
import { createSnack } from "../../redux/SnackPro";
import useUserData from "../../hooks/useUserData";
import useAPI from "../../hooks/useAPI";

const GoogleLogin = () => {
  const [openSignUp, setOpenSignUp] = useState(false);

  const [token, setToken] = useState(
    localStorage.getItem("access_token_google")
  );

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      localStorage.setItem("access_token_google", res.access_token_google);
      setToken(res.access_token_google);
      setOpenSignUp(true);
    },
    onError: () => {
      console.error("Login Failed");
    },
    scope: "https://www.googleapis.com/auth/drive",
  });

  return (
    <>
      <Button
        variant="outlined"
        startIcon={
          <img
            src="https://www.vectorlogo.zone/logos/google/google-icon.svg"
            alt="Google Icon"
            style={{ width: "20px" }}
          />
        }
        size="large"
        onClick={() => login()}
      >
        <span>Log In With Google</span>
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSignUp}
        onClose={() => setOpenSignUp(false)}
        key="log in"
      >
        <Alert severity="success">You are successfully logged in.</Alert>
      </Snackbar>
    </>
  );
};

const Login = ({ setIsLogin }) => {
  const { postAPI } = useAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: {
      value: "",
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      error: false,
    },
    pwd: {
      value: "",
      regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
      error: false,
    },
  });

  const onForm = (e) => {
    const { name, value } = e.target;
    setForm(
      produce((prev) => {
        prev[name].value = value;
        prev[name].error = !prev[name].regex.test(value);
        return prev;
      })
    );
  };

  const { setUserData, setAccessToken } = useUserData();
  const login = () => {
    if (Object.values(form).every((item) => !item.error)) {
      postAPI("user/login", { email: form.email.value, pwd: form.pwd.value })
        .then((data) => {
          setUserData(data.user_data);
          setAccessToken(data.access_token);
          dispatch(
            createSnack({
              severity: "success",
              desc: `Hi, ${data.user_data.name}. Welcome to ClearVision!`,
              duration: 2000,
            })
          );
          setTimeout(() => {
            navigate("/dashboard/cvs", { replace: true });
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            createSnack({
              severity: "error",
              desc: err.response.data.error,
              duration: 2000,
            })
          );
        });
    }
  };

  return (
    <>
      <h1>Log In</h1>

      <GoogleLogin />

      <p>or</p>

      <TextField
        label="Email"
        variant="outlined"
        name="email"
        value={form.email.value}
        error={form.email.error}
        helperText={form.email.error && "Should be a valid email format."}
        onChange={onForm}
        onKeyDown={(e) => e.code === "Enter" && login()}
      />

      <TextField
        label="Password"
        type="password"
        name="pwd"
        variant="outlined"
        value={form.pwd.value}
        error={form.pwd.error}
        helperText={
          form.email.error &&
          "Should contain number and letter, and 8-20 characters long."
        }
        onChange={onForm}
        onKeyDown={(e) => e.code === "Enter" && login()}
      />

      <Button variant="contained" size="large" onClick={() => login()}>
        <span>Log In</span>
      </Button>

      <span onClick={() => setIsLogin(false)}>Don't have an account?</span>
    </>
  );
};

const SignUp = ({ setIsLogin }) => {
  const { postAPI } = useAPI();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: {
      value: "",
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      error: false,
    },
    pwd: {
      value: "",
      regex: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
      error: false,
    },
    confirm: {
      value: "",
      error: false,
    },
  });

  const onForm = (e) => {
    const { name, value } = e.target;
    setForm(
      produce((prev) => {
        prev[name].value = value;
        if ("regex" in prev[name]) {
          prev[name].error = !prev[name].regex.test(value);
        } else {
          prev[name].error = prev[name].value !== form.pwd.value;
        }
        return prev;
      })
    );
  };
  const signUp = () => {
    if (Object.values(form).every((item) => !item.error)) {
      postAPI("user", { email: form.email.value, pwd: form.pwd.value })
        .then((data) => {
          dispatch(
            createSnack({
              severity: "success",
              desc: data.message,
              duration: 3000,
            })
          );
          setIsLogin(true);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            createSnack({
              severity: "error",
              desc: err.response.data.error,
              duration: 2000,
            })
          );
        });
    }
  };

  return (
    <>
      <h1>Sign Up</h1>

      <TextField
        id="outlined-basic"
        label="Email"
        variant="outlined"
        type="email"
        name="email"
        value={form.email.value}
        error={form.email.error}
        onChange={onForm}
        onKeyDown={(e) => e.code === "Enter" && signUp()}
        helperText={form.email.error && "Should be a valid email format."}
      />

      <TextField
        id="outlined-basic"
        label="Password"
        type="password"
        name="pwd"
        variant="outlined"
        value={form.pwd.value}
        error={form.pwd.error}
        onChange={onForm}
        onKeyDown={(e) => e.code === "Enter" && signUp()}
        helperText={
          form.pwd.error &&
          "Should contain number and letter, and 8-20 characters long."
        }
      />

      <TextField
        id="outlined-basic"
        label="Confirm Password"
        type="password"
        name="confirm"
        variant="outlined"
        value={form.confirm.value}
        error={form.confirm.error}
        onChange={onForm}
        onKeyDown={(e) => e.code === "Enter" && signUp()}
        helperText={form.confirm.error && "Passwords do not match."}
      />

      <Button variant="contained" size="large" onClick={() => signUp()}>
        <span>Sign Up</span>
      </Button>

      <span onClick={() => setIsLogin(true)}>Have an account?</span>
    </>
  );
};

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <div id="cv__auth">
      <div className="cv__auth-bg" />
      <div className="cv__auth-box">
        {isLogin ? (
          <Login setIsLogin={setIsLogin} />
        ) : (
          <SignUp setIsLogin={setIsLogin} />
        )}
      </div>
    </div>
  );
};

export default Auth;
