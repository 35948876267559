import React from "react";
import "./Templates.less";
import TemplateCards from "../../components/TemplateCards/TemplateCards";
import Footer from "../../components/Footer/Footer";

const Templates = () => {
  return (
    <>
      <div id="cv__templates">
        <h1>Select a CV Template</h1>

        <TemplateCards />
      </div>
      <Footer />
    </>
  );
};

export default Templates;
