import React, { useEffect, useState } from "react";
import "./Builder.less";
import Editor from "../../pages/Editor/Editor";
import Sidebar from "../../components/Sidebar/Sidebar";
import Tips from "../../components/Tips/Tips";
import ChatGPT from "../../components/ChatGPT/ChatGPT";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DialogPro from "../../components/DialogPro/DialogPro";
import { TextField } from "@mui/material";
import { Alert, AlertTitle, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ComputerIcon from "@mui/icons-material/Computer";
import axios from "axios";
import { createSnack } from "../../redux/SnackPro";
import useUserData from "../../hooks/useUserData";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useAPI from "../../hooks/useAPI";

const Builder = () => {
  const cvData = useSelector((state) => state.cvData);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, setUserData } = useUserData();
  const { putAPI } = useAPI();

  // Jump to export CV page
  const onExport = () => {
    // Get the current template ID from the route
    const array = location.pathname.split("/");
    navigate(`/export-pdf/${array[array.length - 2]}`);
  };

  const [isShowSave, setIsShowSave] = useState(false);
  const [fileName, setFileName] = useState(
    localStorage.getItem("cv_data_file_name") ||
      "CV Data (from cv.harriswong.top)"
  );

  // Save CV Data to Device
  const onSaveDevice = () => {
    // Save the data to be exported to a variable, here we assume that the data is saved in the data variable.
    const data = { ...cvData };
    // Creating JSON Files with Blob Objects
    const jsonBlob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });

    // Create a download link
    const downloadLink = window.URL.createObjectURL(jsonBlob);

    // Create an a tag that simulates a click on the download link to trigger the download
    const a = document.createElement("a");
    a.href = downloadLink;
    a.download = fileName; // File name can be customised
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Save CV Data to Drive
  const onSaveDrive = () => {
    const token = localStorage.getItem("access_token_google");

    if (token) {
      const jsonData = JSON.stringify(cvData);
      const file = new File([jsonData], fileName);

      axios
        .post(
          `https://www.googleapis.com/upload/drive/v3/files?key=AIzaSyAp8saWsv6oDcHpvM4LtEOz27I0eGjslbI`,
          file,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          axios
            .patch(
              `https://www.googleapis.com/drive/v3/files/${res.data.id}?key=AIzaSyAp8saWsv6oDcHpvM4LtEOz27I0eGjslbI`,
              {
                name: fileName,
                description:
                  "This CV metadata is downloaded from cv.harriswong.top",
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then(() => {
              setIsShowSave(false);
              dispatch(
                createSnack({
                  severity: "success",
                  desc: "Uploaded CV data to Google Drive successfully.",
                })
              );
            });
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    } else {
      dispatch(
        createSnack({
          severity: "error",
          desc: "Please sign in to google.",
        })
      );
    }
  };

  // Save CV Data to user's acc
  const onSaveAcc = () => {
    const temp = { ...userData };
    const index = JSON.parse(localStorage.getItem("crt_cv_index"));
    if (index === null) {
      temp.cvs.push({
        title: fileName,
        template: cvData.template.id,
        data: JSON.stringify(cvData),
      });
    } else {
      temp.cvs[index] = {
        ...temp.cvs[index],
        title: fileName,
        template: cvData.template.id,
        data: JSON.stringify(cvData),
      };
    }
    putAPI("user", temp).then(() => {
      setUserData(temp);
      dispatch(
        createSnack({
          severity: "success",
          desc: "Save CV Data Successfully",
          duration: 2000,
        })
      );
      setTimeout(() => {
        navigate("/dashboard/cvs");
      }, 2000);
    });
  };

  // Exit Window Alert
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // An empty string triggers the browser's default prompt message
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div id="cv__builder">
        <Sidebar>
          <Outlet />
        </Sidebar>

        <Editor />

        <div id="cv__rightpanel">
          <Tips />
          <ChatGPT />

          <div className="cv__rightpanel-btns">
            <Button
              onClick={onExport}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              size="large"
            >
              Export PDF
            </Button>

            <Button
              onClick={() => setIsShowSave(true)}
              startIcon={<FileCopyIcon />}
              variant="contained"
              color="secondary"
              size="large"
            >
              Save CV data
            </Button>
          </div>
        </div>
      </div>

      {/* Dialog - Save CV Data Options */}
      <DialogPro
        className="cv__dialogpro-save"
        isShowDialog={isShowSave}
        onClose={() => setIsShowSave(false)}
      >
        <div className="cv__dialogpro-title">Save My CV Data</div>

        <TextField
          label="File Name"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          variant="outlined"
          autoComplete="off"
        />

        {userData && (
          <>
            <Button
              onClick={onSaveAcc}
              startIcon={<AccountCircleIcon />}
              variant="contained"
            >
              Save it to my account
            </Button>
            <p>or</p>
          </>
        )}

        <div className="cv__dialogpro-btns">
          <Button
            onClick={onSaveDevice}
            startIcon={<ComputerIcon />}
            variant="outlined"
          >
            Local Device
          </Button>

          <Button
            onClick={onSaveDrive}
            startIcon={<CloudUploadIcon />}
            variant="outlined"
          >
            Google Drive
          </Button>
        </div>

        <Alert icon={false} severity="warning">
          <AlertTitle>
            <b>Notes</b>
          </AlertTitle>
          <ol>
            <li>
              This is CV metadata in json format, and please do not modify it
              unless you know how it works.
            </li>
            <li>
              Save it to your local device or Google Drive for later editing.
            </li>
          </ol>
        </Alert>
      </DialogPro>
    </>
  );
};

export default Builder;
