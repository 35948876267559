import { configureStore } from "@reduxjs/toolkit";
import themeConfigReducer from "./ThemeConfig";
import cvDataReducer from "./CVData";
import handleDialogReducer from "./HandleDialog";
import snackProReducer from "./SnackPro";

const store = configureStore({
  reducer: {
    themeConfig: themeConfigReducer,
    cvData: cvDataReducer,
    handleDialog: handleDialogReducer,
    snackPro: snackProReducer,
  },
});

export default store;
