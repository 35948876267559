import React from "react";
import { Accordion, Fab, styled, AccordionDetails } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./EditorAccordion.less";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "var(--color-bg-card)",
  flexDirection: "row-reverse",
  minHeight: "initial !important",
  padding: "0 20px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "12px 0",
    transition: ".3s",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "20px 0 12px",
  },
}));

const EditorAccordion = ({ label, length, onDelete, children }) => {
  return (
    <Accordion
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        overflow: "hidden",
        border: "var(--card-border)",
        margin: "0 !important",
      }}
      defaultExpanded={true}
      id="cv__editoraccordion"
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        {/* <Typography>Accordion 1</Typography> */}
        <div className="cv__editoraccordion-summary">
          <div className="cv__chip">{label}</div>
          <Fab
            disabled={length === 1}
            size="small"
            color="error"
            onClick={onDelete}
            variant="extended"
          >
            <DeleteIcon />
            remove
          </Fab>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "8px 20px 20px",
          backgroundColor: "var(--color-bg-card)",
        }}
      >
        {/* <Typography>
            Lorem
          </Typography> */}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default EditorAccordion;
