import React, { useState } from "react";
import "./Start.less";
import cv_templates from "../../assets/cv_templates.svg";
import upload_cv from "../../assets/upload_cv.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadCVData } from "../../redux/CVData";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import { hideDialog, showDialog } from "../../redux/HandleDialog";
import Footer from "../../components/Footer/Footer";
import DialogPro from "../../components/DialogPro/DialogPro";
import GoogleDrivePicker from "../../components/Google/GoogleDrivePicker";
import ComputerIcon from "@mui/icons-material/Computer";
import axios from "axios";

const Start = () => {
  const [open, setOpen] = useState(false);
  const isShowDialog = useSelector((state) => state.handleDialog);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Upload json file from local device
  const onFileUploadDevice = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const jsonData = JSON.parse(reader.result);
        // Upload the jsonData to the store in redux.
        dispatch(uploadCVData(jsonData));
        dispatch(hideDialog());
        navigate(`/builder/${jsonData.template.id}/p_info`);
      } catch (error) {
        setOpen(true);
        console.error(error);
      }
    };
    reader.readAsText(file);
    localStorage.setItem("cv_data_file_name", file.name);
  };

  // Upload json file from Google Drive
  const onFileUploadDrive = (url, name) => {
    localStorage.setItem("cv_data_file_name", name);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "access_token_google"
          )}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch(uploadCVData(res.data));
        dispatch(hideDialog());
        navigate(`/builder/${res.data.template.id}/p_info`);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  return (
    <>
      <div id="cv__start">
        <h1>How would you like to start?</h1>

        <div className="cv__start-box-wrap">
          <div
            className="cv__start-box cv__card-link"
            onClick={() => navigate("/templates")}
          >
            <div className="cv__start-box-img">
              <img src={cv_templates} alt="cv_templates" />
            </div>
            <div className="cv__start-text-wrap">
              <p className="cv__start-title">Create a New CV</p>
              <p className="cv__start-desc">
                Select a CV template and start a new CV
              </p>
            </div>
          </div>

          <div
            className="cv__start-box cv__card-link"
            onClick={() => dispatch(showDialog())}
          >
            <div className="cv__start-box-img">
              <img src={upload_cv} alt="upload_cv" />
            </div>
            <div className="cv__start-text-wrap">
              <p className="cv__start-title">Upload My CV Data</p>
              <p className="cv__start-desc">
                Continue with your previous CV edited on this website
              </p>
            </div>
          </div>

          <DialogPro className="cv__upload-cv-data" isShowDialog={isShowDialog}>
            <h2>Upload My CV Data</h2>
            <Alert icon={false} severity="warning">
              <AlertTitle>
                <b>Note</b>
              </AlertTitle>
              <ol>
                <li>
                  Please upload the CV data file downloaded from this website.
                </li>
                <li>
                  Please ensure that you do not modify the CV data file unless
                  you know what you are doing.
                </li>
              </ol>
            </Alert>

            <div className="cv__upload-btns">
              <Button
                variant="contained"
                startIcon={<ComputerIcon />}
                component="label"
              >
                Local Device
                <input
                  hidden
                  type="file"
                  accept=".json"
                  onChange={(e) => onFileUploadDevice(e.target.files[0])}
                />
              </Button>

              <GoogleDrivePicker onFileUpload={onFileUploadDrive} />
            </div>
          </DialogPro>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        key="top_center"
      >
        <Alert severity="error">
          <AlertTitle>
            <b>Error</b>
          </AlertTitle>
          1. Please upload the CV data file downloaded from this website.
          <br />
          2. Please ensure that you do not modify the CV data file unless you
          know what you are doing.
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
};

export default Start;
