import React from "react";
import "./Footer.less";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="cv__footer">
      <div>
        Made with ♥ by{" "}
        <Link
          className="cv__link-text"
          to="https://harriswong.top"
          target="_blank"
        >
          Harris Wong
        </Link>{" "}
        © Since 2023
      </div>

      <div className="right">
        <Link
          className="cv__link-text"
          to="https://drive.google.com/file/d/1_vkxhUc0-l68a1o2ITQXF54nTlozKoCC/view?usp=sharing"
          target="_blank"
        >
          Website Owner's CV
        </Link>
        <span>|</span>
        <Link to="/privacy" className="cv__link-text">
          Privacy Policy
        </Link>
        <span>|</span>
        <Link to="mailto:huangshisong89@gmail.com" className="cv__link-text">
          Feedback
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
