import "./Dashboard.less";
import classic from "../../assets/classic.png";
import { Link, useNavigate } from "react-router-dom";
import { uploadCVData } from "../../redux/CVData";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useAPI from "../../hooks/useAPI";
import useUserData from "../../hooks/useUserData";

// const data = [
//   {
//     img: classic,
//     title: "CV - Shisong Huang",
//     template: "classic",
//   },
//   {
//     img: classic,
//     title: "CV - Shisong Huang - Frontend Developer",
//     template: "classic",
//   },
//   {
//     img: classic,
//     title: "CV - Shisong Huang - Software Engineer",
//     template: "classic",
//   },
// ];

const CVs = () => {
  // const {  } = useAPI();
  const { userData } = useUserData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toBuilderPage = (template) => {
    // dispatch(uploadCVData());
    navigate(`/builder/${template}/p_info`);
  };
  console.log(userData.cvs[0]);

  return (
    <div id="cvs">
      {userData.cvs.length ? (
        <div className="cvs">
          {userData.cvs.map((card) => (
            <div
              className="card"
              key={`${card.title} + ${card.template}`}
              onClick={() => toBuilderPage(card.template)}
            >
              <img src={card.img} alt="User's CV" />
              <span>{card.title}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="no_data">
          No CV? No worries!{" "}
          <Link to="/templates" className="cv__link-text">
            Let's get started
          </Link>{" "}
          !
        </div>
      )}
    </div>
  );
};

export default CVs;
