import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DialogPro from "../DialogPro/DialogPro";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { onFormChange } from "../../redux/CVData";
import { createSnack } from "../../redux/SnackPro";

const SmartFunc = ({ onSend, setIsGeneration, hasAPI }) => {
  const [isShowGeneration, setIsShowGeneration] = useState(false);
  const [preGeneration, setPreGeneration] = useState({
    sections: {},
    requirements: "",
  });
  const [isShowInspiration, setIsShowInspiration] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const cvData = useSelector((state) => state.cvData);
  // Removing template attributes using deconstructed assignments
  const { template, p_info, edu, ...sectionsGeneration } = cvData;

  const onShowInspiration = () => {
    if (cvData.p_info.job_title) {
      onInspiration();
    } else {
      setIsShowInspiration(true);
    }
  };

  // Generate CV Content
  const onGeneration = () => {
    let promptGeneration = `This is the CV JS Object format: ${JSON.stringify(
      preGeneration.sections
    )} Please generate the CV data of this part based on my ${
      cvData.p_info.job_title
    } job title. ${
      preGeneration.requirements
        ? `Requirement: ${preGeneration.requirements}.`
        : ""
    } Output format: Only Markdown.`;

    setIsGeneration(true);
    setIsShowGeneration(false);
    onSend(promptGeneration);
  };
  const onPreGeneration = (e) => {
    const { name, checked } = e.target;
    setPreGeneration(
      produce((draft) => {
        if (checked) {
          draft.sections[name] = cvData[name];
        } else {
          delete draft.sections[name];
        }
      })
    );
  };

  // Inspire CV Ideas
  const onInspiration = () => {
    const array = location.pathname.split("/");
    let section = array[array.length - 1];
    let promptInspiration = `This is my CV JS Object data: ${JSON.stringify(
      cvData[section]
    )} Please provide short tips for writing ${
      cvData[section].title
    } section well and a short example based on my ${
      cvData.p_info.job_title
    } job title and CV data. Output format: Only Markdown.`;

    setIsShowInspiration(false);
    onSend(promptInspiration);
  };

  // Evaluate & Optimize
  const onEvaluation = () => {
    // Process your CV Data
    let { template, ...yourCVData } = cvData;
    // Anonymize the personal info
    yourCVData.p_info = {
      title: "Personal Info",
      name: "",
      job_title: yourCVData.p_info.job_title,
      location: "",
      phone_num: "",
      email: "",
      url: "",
      github: "",
      intro: yourCVData.p_info.intro,
    };
    // Remove unchecked CV sections
    for (const key in yourCVData) {
      if (
        yourCVData[key].hasOwnProperty("enable") &&
        yourCVData[key].enable === false
      ) {
        delete yourCVData[key];
      }
    }
    let promptEvaluation = `This is my CV JS Object data: ${JSON.stringify(
      yourCVData
    )} Please give a short evaluation of my CV based on spelling, grammar, diction, and compliance with the norms of the profession and make short suggestions for revision. Output format: Only Markdown.`;
    onSend(promptEvaluation);
  };

  const onButtonPreprocessing = (func) => {
    if (hasAPI) {
      func();
    } else {
      dispatch(
        createSnack({
          severity: "warning",
          desc: "Please Input Your ChatGPT API Key",
          duration: 3000,
        })
      );
    }
  };

  return (
    <>
      <div className="cv__chatgpt-funcs">
        <div
          className="cv__chatgpt-func cv__card-link"
          onClick={() => onButtonPreprocessing(() => setIsShowGeneration(true))}
        >
          <AutoFixHighIcon />
          <h3>Generate Your CV</h3>
        </div>
        <div
          className="cv__chatgpt-func cv__card-link"
          onClick={() => onButtonPreprocessing(onShowInspiration)}
        >
          <TipsAndUpdatesIcon />
          <h3>Inspire CV Ideas</h3>
        </div>
        <div
          className="cv__chatgpt-func cv__card-link"
          onClick={() => onButtonPreprocessing(onEvaluation)}
        >
          <AssessmentIcon />
          <h3>Evaluate & Optimize</h3>
        </div>
      </div>

      {/* Dialog - Generate Your CV - Prerequisite */}
      <DialogPro
        className="cv__dialogpro-generation-pre"
        isShowDialog={isShowGeneration}
        onClose={() => setIsShowGeneration(false)}
      >
        <div className="cv__dialogpro-title">
          Generate Your CV - Prerequisite
        </div>
        <TextField
          label="Your Job Title"
          name="job_title"
          value={cvData.p_info.job_title}
          onChange={(e) =>
            dispatch(onFormChange("p_info", "job_title", e.target.value))
          }
          variant="outlined"
          autoComplete="off"
        />
        <TextField
          label="Requirements for CV Generation"
          name="requirements"
          value={preGeneration.requirements}
          onChange={(e) =>
            setPreGeneration(
              produce((draft) => {
                draft.requirements = e.target.value;
              })
            )
          }
          variant="outlined"
          autoComplete="off"
        />

        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            {Object.entries(sectionsGeneration).map(
              ([item, section], index) => (
                <FormControlLabel
                  checked={preGeneration.sections.hasOwnProperty(item)}
                  control={<Checkbox onChange={onPreGeneration} name={item} />}
                  label={section.title}
                  key={`cv__sidebar-editsection-${index}`}
                />
              )
            )}
          </FormGroup>
        </FormControl>
        <br />

        <Button
          onClick={onGeneration}
          startIcon={<AutoFixHighIcon />}
          variant="contained"
        >
          Generate Your CV
        </Button>
      </DialogPro>

      {/* Dialog - Inspire CV Ideas - Prerequisite */}
      <DialogPro
        className="cv__dialogpro-inspiration-pre"
        isShowDialog={isShowInspiration}
        onClose={() => setIsShowInspiration(false)}
      >
        <div className="cv__dialogpro-title">
          Inspire CV Ideas - Prerequisite
        </div>
        <TextField
          label="Your Job Title"
          name="job_title"
          value={cvData.p_info.job_title}
          onChange={(e) =>
            dispatch(onFormChange("p_info", "job_title", e.target.value))
          }
          variant="outlined"
          autoComplete="off"
        />

        <Button
          onClick={onInspiration}
          startIcon={<TipsAndUpdatesIcon />}
          variant="contained"
        >
          Inspire CV Ideas
        </Button>
      </DialogPro>
    </>
  );
};

export default SmartFunc;
