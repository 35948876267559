import { useEffect, useState } from "react";

const useUserData = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user_data"))
  );
  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem("access_token"))
  );

  useEffect(() => {
    if (userData) {
      localStorage.setItem("user_data", JSON.stringify(userData));
    } else {
      localStorage.removeItem("user_data");
    }
  }, [userData]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem("access_token", JSON.stringify(accessToken));
    } else {
      localStorage.removeItem("access_token");
    }
  }, [accessToken]);

  return {
    userData,
    setUserData,
    accessToken,
    setAccessToken,
  };
};

export default useUserData;
