import React, { useState } from "react";
import "./Navbar.less";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { switchTheme } from "../../redux/ThemeConfig";
import templateData from "../../data/TemplatesData";
import TemplateDetail from "../TemplateCards/TemplateDetail";
import PopupMenu from "../PopupMenu/PopupMenu";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { createSnack } from "../../redux/SnackPro";
import useUserData from "../../hooks/useUserData";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  margin: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(22px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(6px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "#ffffff1a" : "#0000001a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#5a26e8" : "#3d993d",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#ffffff1a" : "#0000001a",
    borderRadius: 20 / 2,
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.themeConfig.palette.mode);

  // Is the current template name displayed?
  const rawLocation = useLocation();
  const location = rawLocation.pathname.split("/")[1];
  const tempID = useSelector((state) => state.cvData.template.id);
  const tempData = templateData.find((item) => item.template.id === tempID);

  const [isZoomIn, setIsZoomIn] = useState(false);

  const menuData1 = [
    <Link to="/templates">Explore more templates</Link>,
    <div onClick={() => setIsZoomIn(true)}>About this template</div>,
  ];
  const menuData2 = [
    <Link to="/dashboard/cvs">My CVs</Link>,
    <Link to="/dashboard/profile">My Profile</Link>,
    <div onClick={() => logout()}>Log Out</div>,
  ];

  // useEffect(() => {
  //   if (token) {
  //     axios
  //       .get("https://www.googleapis.com/oauth2/v3/userinfo", {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       .then((userInfo) => {
  //         setUser(userInfo.data);
  //       })
  //       .catch((err) => {
  //         dispatch(
  //           createSnack({
  //             severity: "warning",
  //             desc: "Your session has expired. Please log in again.",
  //           })
  //         );
  //         setUser(null);
  //         setToken(null);
  //         localStorage.removeItem("access_token_google");
  //         console.error(err);
  //       });
  //   }
  // }, [token]);
  const { userData, setUserData, setAccessToken } = useUserData();
  const logout = () => {
    // googleLogout();
    // localStorage.removeItem("access_token_google");
    if (location === "dashboard") {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
    setUserData(null);
    setAccessToken(null);
    dispatch(
      createSnack({
        severity: "success",
        desc: "You are successfully logged out.",
        duration: 2000,
      })
    );
  };

  return (
    <>
      <nav id="cv__navbar" className={mode === "light" ? "" : "dark"}>
        <div className="cv__navbar-logo">
          <Link className="cv__link-text" to="/">
            ClearVision
          </Link>
        </div>

        {location === "builder" && (
          <div className="cv__navbar-template">
            <PopupMenu menuData={menuData1}>
              <p>
                Template:{" "}
                {tempData?.title ? (
                  <b>{tempData?.title}</b>
                ) : (
                  <b>Please select the template‼️</b>
                )}
              </p>
            </PopupMenu>
          </div>
        )}

        <div className="cv__navbar-func">
          <FormControlLabel
            control={
              <MaterialUISwitch checked={mode === "light"} sx={{ m: 1 }} />
            }
            onClick={() => dispatch(switchTheme())}
          />

          {userData ? (
            <PopupMenu menuData={menuData2}>
              <div className="cv__navbar-user">
                <img src={userData.avatar} alt="Avatar" />
                <span>{userData.name}</span>
              </div>
            </PopupMenu>
          ) : (
            <Button
              variant="contained"
              size="small"
              startIcon={<LoginIcon />}
              onClick={() => navigate("/auth")}
            >
              <span>Sign in</span>
            </Button>
          )}
        </div>
      </nav>

      {/* Dialog - Template Detail */}
      <TemplateDetail
        isZoomIn={isZoomIn}
        setIsZoomIn={setIsZoomIn}
        crtTemp={tempData}
      />
    </>
  );
};

export default Navbar;
