import React, { useEffect } from "react";
import "./ExportPDF.less";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useCustomStyle from "../../hooks/useCustomStyle";

const ExportPDF = () => {
  const navigate = useNavigate();

  // CV Preview Custom Style
  const csdata = useSelector((state) => state.cvData.template);

  useCustomStyle(csdata);

  useEffect(() => {
    // Perform the built-in print function
    const timer = setTimeout(() => {
      window.print();
      navigate(-1);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className="cv__template-export cv__template cv__card">
      <Outlet />
    </div>
  );
};

export default ExportPDF;
