import axios from "axios";
import useUserData from "./useUserData";

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : "https://clear-vision-api.vercel.app/";

const useAPI = () => {
  const { accessToken } = useUserData();
  const config = accessToken
    ? { headers: { Authorization: `Bearer ${accessToken}` } }
    : {};

  const getAPI = async (path) => {
    const res = await axios.get(`${API_BASE_URL}${path}`, config);
    return res.data;
  };

  const postAPI = async (path, data) => {
    const res = await axios.post(`${API_BASE_URL}${path}`, data, config);
    return res.data;
  };

  const putAPI = async (path, data) => {
    const res = await axios.put(`${API_BASE_URL}${path}`, data, config);
    return res.data;
  };

  const deleteAPI = async (path) => {
    const res = await axios.delete(`${API_BASE_URL}${path}`, config);
    return res.data;
  };

  return { getAPI, postAPI, putAPI, deleteAPI };
};

export default useAPI;
