import React from "react";
import "./Minimalist.less";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LinkIcon from "@mui/icons-material/Link";
import GitHubIcon from "@mui/icons-material/GitHub";
import { LinkedIn } from "@mui/icons-material";

const Section = ({ title, isShow, children }) => {
  return (
    (isShow === undefined || isShow) && (
      <div className="cvt__minimalist-section">
        <h2 className="cvt__minimalist-section-title cvt__themecolor">
          {title}
        </h2>
        {children}
      </div>
    )
  );
};

const Headline = ({ data }) => {
  return (
    <>
      <h1 className="cvt__minimalist-name cvt__themecolor">{data.name}</h1>
      {data.job_title && (
        <p className="cvt__minimalist-jobtitle">{data.job_title}</p>
      )}
    </>
  );
};

const ContactItem = ({ icon, data }) => {
  return (
    data && (
      <div>
        {icon}
        <p>{data}</p>
      </div>
    )
  );
};

const Contact = ({ data }) => {
  return (
    <div className="cvt__minimalist-contact">
      <ContactItem icon={<LocationOnIcon />} data={data.location} />
      <ContactItem icon={<PhoneIphoneIcon />} data={data.phone_num} />
      <ContactItem icon={<EmailIcon />} data={data.email} />
      <ContactItem icon={<LinkedIn />} data={data.linkedin} />
      <ContactItem icon={<LinkIcon />} data={data.url} />
      <ContactItem icon={<GitHubIcon />} data={data.github} />
    </div>
  );
};

const Profile = ({ data }) => {
  return (
    <ReactMarkdown className="cvt__minimalist-intro">{data}</ReactMarkdown>
  );
};

const Education = ({ data }) => {
  return (
    <div className="cvt__minimalist-edu">
      {data.item.map((item, index) => (
        <div
          className="cvt__minimalist-edu-item"
          key={`cvt__minimalist-edu-item-${index}`}
        >
          <div className="cvt__minimalist-edu-item-flex">
            {item.school && (
              <p>
                <b>{item.school}</b>
              </p>
            )}
            {(item.start_date || item.end_date) && (
              <p>
                {item.start_date} - {item.end_date}
              </p>
            )}
          </div>
          <div className="cvt__minimalist-edu-item-flex">
            {(item.degree || item.major) && (
              <p>
                {item.degree} in {item.major}
              </p>
            )}
            {item.location && (
              <p style={{ textDecoration: "underline" }}>{item.location}</p>
            )}
          </div>
          <ul>
            {item.desc.map(
              (item, index) =>
                item && (
                  <li key={`cvt__minimalist-experiences-li-${index}`}>
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </li>
                )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

const Skills = ({ data }) => {
  return (
    <div className="cvt__minimalist-skills">
      {data.item.map((item, index) => (
        <div
          className="cvt__minimalist-skills-item"
          key={`cvt__minimalist-skills-item-${index}`}
        >
          {item.ski_category && (
            <p>
              <b>{item.ski_category}</b>
            </p>
          )}
          <div className="cvt__minimalist-skills-skillset">
            <p>{item.skills.join(" / ")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ data }) => {
  return (
    <div className="cvt__minimalist-projects">
      {data.item.map((item, index) => (
        <div
          className="cvt__minimalist-projects-item"
          key={`cvt__minimalist-projects-item-${index}`}
        >
          <div className="cvt__minimalist-projects-wrap">
            <p>
              <b>{item.prj_name}</b>
            </p>
            <p>
              {item.start_date} - {item.end_date}
            </p>
          </div>
          <ul>
            {item.desc.map(
              (item, idx) =>
                item && (
                  <li key={`cvt__minimalist-projects-li-${index}-${idx}`}>
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </li>
                )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

const Experience = ({ data }) => {
  return (
    <div className="cvt__minimalist-experiences">
      {data.item.map((item, index) => (
        <div
          className="cvt__minimalist-experiences-item"
          key={`cvt__minimalist-experiences-item-${index}`}
        >
          <div className="cvt__minimalist-experiences-flex">
            {item.position && (
              <p>
                <b>{item.position}</b>
              </p>
            )}
            {(item.start_date || item.end_date) && (
              <p>
                {item.start_date} - {item.end_date}
              </p>
            )}
          </div>
          {item.company && (
            <p>
              <b>{item.company}</b>
            </p>
          )}
          <ul>
            {item.desc.map(
              (item, idx) =>
                item && (
                  <li key={`cvt__minimalist-experiences-li-${index}-${idx}`}>
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </li>
                )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

const Hobbies = ({ data }) => {
  return (
    <div className="cvt__minimalist-hobbies">
      {data.item.map((item, index) => (
        <div
          className="cvt__minimalist-hobbies-item"
          key={`cvt__minimalist-hobbies-item-${index}`}
        >
          {item.hb_category && (
            <p>
              <b>{item.hb_category}</b>
            </p>
          )}
          <div className="cvt__minimalist-hobbies-items">
            <p>{item.hobbies.join(" / ")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const Minimalist = () => {
  const cvData = useSelector((state) => state.cvData);
  return (
    <div id="cvt__minimalist">
      <Headline data={cvData.p_info} />

      <div className="cvt__minimalist-wrap">
        <div className="cvt__minimalist-left">
          <Section title="Contact">
            <Contact data={cvData.p_info} />
          </Section>
          <Section title="Profile" isShow={cvData.p_info.intro}>
            <Profile data={cvData.p_info.intro} />
          </Section>
          <Section title={cvData.ski.title}>
            <Skills data={cvData.ski} />
          </Section>
          <Section title={cvData.hb.title} isShow={cvData.hb.enable}>
            <Hobbies data={cvData.hb} />
          </Section>
        </div>

        <div className="cvt__minimalist-right">
          <Section title={cvData.edu.title}>
            <Education data={cvData.edu} />
          </Section>
          <Section title={cvData.prj.title} isShow={cvData.prj.enable}>
            <Projects data={cvData.prj} />
          </Section>
          <Section title={cvData.exp.title} isShow={cvData.exp.enable}>
            <Experience data={cvData.exp} />
          </Section>
        </div>
      </div>
    </div>
  );
};

export default Minimalist;
