import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import EditIcon from "@mui/icons-material/Edit";

const DashedButton = ({ type, name, color, onClick }) => {
  const style_button = {
    width: "100%",
    borderStyle: "dashed",
  };

  let startIcon;

  switch (type) {
    case "add":
      startIcon = <AddCircleOutlineIcon />;
      break;
    case "edit":
      startIcon = <EditIcon />;
      break;
    case "custom":
      startIcon = <ColorLensIcon />;
      break;
    default:
      startIcon = <EditIcon />;
  }

  return (
    <Button
      variant="outlined"
      size="large"
      startIcon={startIcon}
      onClick={onClick}
      color={color}
      className="cv__dashed-button"
      style={style_button}
    >
      {name}
    </Button>
  );
};

export default DashedButton;
