import React from "react";
import { Link } from "react-router-dom";

const FooterMini = () => {
  return (
    <footer>
      © 2023 By{" "}
      <Link className="cv__link-text" to="https://harriswong.top">
        Harris Wong
      </Link>
    </footer>
  );
};

export default FooterMini;
