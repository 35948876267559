import { onFormChange } from "../../redux/CVData";
import "./Dashboard.less";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import MDEditorPro from "../../components/MDEditor/MDEditorPro/MDEditorPro";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const Profile = () => {
  const { p_info } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("p_info", name, value));
  };

  const handleMDChange = (name, value) => {
    dispatch(onFormChange("p_info", name, value));
  };

  return (
    <div id="profile">
      <div className="grid">
        <TextField
          label="Name"
          name="name"
          value={p_info.name}
          onChange={handleChange}
          variant="outlined"
          autoComplete="off"
        />
        <TextField
          label="Job Title"
          name="job_title"
          value={p_info.job_title}
          onChange={handleChange}
          variant="outlined"
          autoComplete="off"
        />
        <TextField
          label="Location"
          name="location"
          value={p_info.location}
          onChange={handleChange}
          variant="outlined"
          autoComplete="off"
        />
        <TextField
          label="Phone Number"
          name="phone_num"
          value={p_info.phone_num}
          onChange={handleChange}
          variant="outlined"
          autoComplete="off"
        />
        <MDEditorPro
          label="Email"
          value={p_info.email}
          onChange={(value) => handleMDChange("email", value)}
        />
        <MDEditorPro
          label="LinkedIn"
          value={p_info.linkedin}
          onChange={(value) => handleMDChange("linkedin", value)}
        />
        <MDEditorPro
          label="Web Link"
          value={p_info.url}
          onChange={(value) => handleMDChange("url", value)}
        />
        <MDEditorPro
          label="GitHub (If you have)"
          value={p_info.github}
          onChange={(value) => handleMDChange("github", value)}
        />
      </div>
      <MDEditorPro
        label="Introduction / Summary / Profile"
        value={p_info.intro}
        onChange={(value) => handleMDChange("intro", value)}
      />

      <Button variant="contained" color="primary" startIcon={<SaveIcon />}>
        Save
      </Button>
    </div>
  );
};

export default Profile;
