import { useEffect, useState } from "react";
import "./Privacy.less";
import PrivacyPolicy from "./Privacy Policy.md";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Link } from "react-router-dom";

const Privacy = () => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(PrivacyPolicy)
      .then((res) => res.text())
      .then((data) => setMarkdown(data));
  });

  return (
    <div id="cv__privacy">
      <ReactMarkdown
        components={{
          a(props) {
            console.log(props);
            return (
              <Link to={props.href} target="_blank">
                {props.children[0]}
              </Link>
            );
          },
        }}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

export default Privacy;
