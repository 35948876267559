import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  addFormItemArray,
  deleteFormItem,
  deleteFormItemArray,
  onFormChange,
  onFormItemObjectChange,
  onFormItemChange,
} from "../../../redux/CVData";
import { TextField } from "@mui/material";
import DashedButton from "../../../components/DashedButton/DashedButton";
import EditorAccordion from "../../../components/EditorAccordion/EditorAccordion";
import TextFieldAction from "../../../components/TextFieldAction/TextFieldAction";

const HbItem = ({ itemIndex }) => {
  const { hb } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();
  const handleFormItemArrayChange = (itemIndex, arrayIndex, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemObjectChange("hb", itemIndex, arrayIndex, name, value));
  };

  return (
    <>
      {hb.item[itemIndex].hobbies.map((item, arrayIndex) => (
        <TextFieldAction
          label="Hobby"
          name="hobbies"
          value={item}
          onChange={(e) => handleFormItemArrayChange(itemIndex, arrayIndex, e)}
          onDelete={() =>
            dispatch(
              deleteFormItemArray("hb", "hobbies", itemIndex, arrayIndex)
            )
          }
          length={hb.item[itemIndex].hobbies.length}
          key={`cv__hb-item-${arrayIndex + 1}`}
        />
      ))}
      <DashedButton
        type="add"
        name={"Add Hobby"}
        onClick={() => dispatch(addFormItemArray("hb", "hobbies", itemIndex))}
      />
    </>
  );
};

const HbCategory = () => {
  const { hb } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormItemChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemChange("hb", index, name, value));
  };

  return (
    <>
      {hb.item.map((item, itemIndex) => (
        <EditorAccordion
          label={
            item.hb_category
              ? item.hb_category
              : `Hobby Category ${itemIndex + 1}`
          }
          length={hb.item.length}
          onDelete={() => dispatch(deleteFormItem("hb", itemIndex))}
          key={`cv__hb-category-${itemIndex + 1}`}
        >
          <TextField
            label="Hobby Category"
            name="hb_category"
            value={item.hb_category}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />

          <HbItem itemIndex={itemIndex} />
        </EditorAccordion>
      ))}
      <DashedButton
        type="add"
        name={"Add Hobby Category"}
        onClick={() => dispatch(addFormItem("hb"))}
      />
    </>
  );
};

const Hobbies = () => {
  const { hb } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("hb", name, value));
  };

  return (
    <div id="cv__hobbies">
      <TextField
        label="Section Title"
        name="title"
        value={hb.title}
        onChange={handleFormChange}
        variant="outlined"
        autoComplete="off"
      />

      <HbCategory />
    </div>
  );
};

export default Hobbies;
