import React from "react";
import "./Editor.less";
import { Navigate, useParams } from "react-router-dom";
import sectionData from "../../data/SectionData";

const Editor = () => {
  const { sectionId } = useParams();
  const Section = sectionData.find((item) => item.id === sectionId)?.component;
  if (!Section) {
    return <Navigate to="/404" />;
  }

  return <div id="cv__editor">{Section}</div>;
};

export default Editor;
