import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnack } from "../../redux/SnackPro";

const SnackPro = () => {
  const { open, duration, severity, desc } = useSelector(
    (state) => state.snackPro
  );
  const dispatch = useDispatch();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={() => dispatch(closeSnack())}
      key="desc"
      autoHideDuration={duration}
    >
      <Alert severity={severity}>{desc}</Alert>
    </Snackbar>
  );
};

export default SnackPro;
