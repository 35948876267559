import produce from "immer";
import Classic from "../templates/Classic/Classic";
import Minimalist from "../templates/Minimalist/Minimalist";
import classic from "../assets/classic.png";
import minimalist from "../assets/minimalist.png";

const rawTemplateData = [
  {
    title: "Classic",
    element: <Classic />,
    img: classic,
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "This is a very classic one.",
    template: {
      font_size_title: 22,
      font_size_text: 14,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "Minimalist White and Grey Professional CV",
    element: <Minimalist />,
    img: minimalist,
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Minimalist White and Grey Professional CV",
    template: {
      font_size_title: 22,
      font_size_text: 14,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#4c9993",
    },
  },
  {
    title: "Minimalist Clean Signature CV Resume",
    element: <Classic />,
    img: "https://marketplace.canva.com/EAE8giEozw8/2/0/566w/canva-CRm5I756X5A.jpg",
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Hope you like it.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "Pastel Pink Light Blue Clean UI Web Developer CV",
    element: <Classic />,
    img: "https://marketplace.canva.com/EAFKBVTrL64/1/0/566w/canva-9dj48BnLNyw.jpg",
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Hope you like it.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "Blue and White Modern Nurse Resume",
    element: <Classic />,
    img: "https://marketplace.canva.com/EAE5uNFzmn4/1/0/566w/canva-QQ2LsftpUG4.jpg",
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Hope you like it.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "Minimalist White and Grey Professional Resume",
    element: <Classic />,
    img: "https://marketplace.canva.com/EAFJ2vcWX2c/1/0/566w/canva-lhtGDB4Ohl8.jpg",
    author: "Harris Wong",
    email: "hassan89@163.com",
    desc: "a clean and sophisticated resume template. Its minimal design in white and grey exudes professionalism and simplicity, making it perfect for showcasing your skills and experience in a sleek and modern format.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "White and Pastel Teal Minimalist Resume",
    element: <Classic />,
    img: "https://marketplace.canva.com/EADapIUg5_k/1/0/618w/canva-AZf4ny8LD2Y.jpg",
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Hope you like it.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
  {
    title: "Green Grey Color Blocks Copy Editor CV",
    element: <Classic />,
    img: "https://marketplace.canva.com/EAFpCRiaTPc/1/0/566w/canva-dZteK1ujOVE.jpg",
    author: "Harris Wong (Shisong)",
    email: "hassan89@163.com",
    desc: "Hope you like it.",
    template: {
      font_size_title: 22,
      font_size_text: 16,
      font_family_title: "",
      font_family_text: "",
      theme_color: "#000000",
    },
  },
];

const templateData = produce(rawTemplateData, (draft) => {
  draft.forEach((item) => {
    item.template.id = item.title.toLowerCase().replace(/ /g, "_");
  });
});

export default templateData;
