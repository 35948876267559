import React from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <GoogleOAuthProvider clientId="965974719137-n7uamkmbte6bic1q2m12q3vos780e7bf.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Router>
  </Provider>
);
