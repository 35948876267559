import React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  addFormItemArray,
  deleteFormItem,
  deleteFormItemArray,
  onFormChange,
  onFormItemObjectChange,
  onFormItemChange,
  onFormItemReplace,
} from "../../../redux/CVData";
import EditorAccordion from "../../../components/EditorAccordion/EditorAccordion";
import DashedButton from "../../../components/DashedButton/DashedButton";
import TextFieldAction from "../../../components/TextFieldAction/TextFieldAction";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SkiItem = ({ value, itemIndex, arrayIndex, length }) => {
  const dispatch = useDispatch();
  const handleFormItemArrayChange = (itemIndex, arrayIndex, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemObjectChange("ski", itemIndex, arrayIndex, name, value));
  };
  return (
    <TextFieldAction
      label="Skill"
      name="skills"
      value={value}
      length={length}
      onChange={(e) => handleFormItemArrayChange(itemIndex, arrayIndex, e)}
      onDelete={() =>
        dispatch(deleteFormItemArray("ski", "skills", itemIndex, arrayIndex))
      }
    />
  );
};

const SkiItems = ({ itemIndex }) => {
  const skiItemsData = useSelector(
    (state) => state.cvData.ski.item[itemIndex].skills
  );
  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const onDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      const oldIndex = skiItemsData.indexOf(active.id);
      const newIndex = skiItemsData.indexOf(over.id);
      dispatch(
        onFormItemReplace(
          "ski",
          itemIndex,
          "skills",
          arrayMove(skiItemsData, oldIndex, newIndex)
        )
      );
    }
  };
  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={skiItemsData}
          strategy={verticalListSortingStrategy}
        >
          {skiItemsData.map((item, arrayIndex) => (
            <SkiItem
              key={`cv__ski-item-${arrayIndex + 1}`}
              value={item}
              itemIndex={itemIndex}
              arrayIndex={arrayIndex}
              length={skiItemsData.length}
            />
          ))}
        </SortableContext>
      </DndContext>
      <DashedButton
        type="add"
        name={"Add Skill"}
        onClick={() => dispatch(addFormItemArray("ski", "skills", itemIndex))}
      />
    </>
  );
};

const SkiCategory = () => {
  const { ski } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormItemChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemChange("ski", index, name, value));
  };

  return (
    <>
      {ski.item.map((item, itemIndex) => (
        <EditorAccordion
          label={
            item.ski_category
              ? item.ski_category
              : `Skill Category ${itemIndex + 1}`
          }
          length={ski.item.length}
          onDelete={() => dispatch(deleteFormItem("ski", itemIndex))}
          key={`cv__ski-category-${itemIndex + 1}`}
        >
          <TextField
            label="Skill Category"
            name="ski_category"
            value={item.ski_category}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />

          <SkiItems itemIndex={itemIndex} />
        </EditorAccordion>
      ))}
      <DashedButton
        type="add"
        name={"Add Skill Category"}
        onClick={() => dispatch(addFormItem("ski"))}
      />
    </>
  );
};

const Skills = () => {
  const { ski } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("ski", name, value));
  };

  return (
    <div id="cv__skills">
      <TextField
        label="Section Title"
        name="title"
        value={ski.title}
        onChange={handleFormChange}
        variant="outlined"
        autoComplete="off"
      />

      <SkiCategory />
    </div>
  );
};

export default Skills;
