import { useSelector } from "react-redux";
import "./App.less";
import Navbar from "./components/Navbar/Navbar";
import Router from "./router/Router";
import { ThemeProvider, createTheme } from "@mui/material";
import SnackPro from "./components/SnackPro/SnackPro";

function App() {
  const themeConfig = useSelector((state) => state.themeConfig);
  const mode = themeConfig.palette.mode;
  const theme = createTheme(themeConfig);

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <SnackPro />

      <div
        data-color-mode={mode === "light" ? "light" : " dark"}
        className={`cv__layout${mode === "light" ? "" : " dark"}`}
      >
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
