import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import "./Dashboard.less";
import { Button } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import useUserData from "../../hooks/useUserData";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.pathname.split("/").at(-1);

  const { userData } = useUserData();

  return (
    <div id="cv__dashboard">
      <div className="container">
        <div className="info">
          <img src={userData.avatar} alt="avatar" />
          <span>Welcome back, {userData.name}!</span>
          <Button
            className="create"
            variant="contained"
            startIcon={<NoteAddIcon />}
            onClick={() => navigate("/templates")}
          >
            Create a New CV
          </Button>
        </div>

        <div className="tabs">
          <Link
            to="/dashboard/cvs"
            style={tab === "cvs" ? { color: "var(--color-theme)" } : {}}
          >
            CVs
          </Link>
          <Link
            to="/dashboard/profile"
            style={tab === "profile" ? { color: "var(--color-theme)" } : {}}
          >
            Profile
          </Link>
        </div>

        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
