import React, { useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";

const GoogleDrivePicker = ({ onFileUpload }) => {
  const [openPicker] = useDrivePicker();
  const token = localStorage.getItem("access_token_google");

  const [open, setOpen] = useState(false);

  const handleOpenPicker = () => {
    if (token) {
      openPicker({
        clientId:
          "965974719137-n7uamkmbte6bic1q2m12q3vos780e7bf.apps.googleusercontent.com",
        developerKey: "AIzaSyAp8saWsv6oDcHpvM4LtEOz27I0eGjslbI",
        viewMimeTypes: "application/json",
        token: token,
        supportDrives: true,
        callbackFunction: (data) => {
          switch (data.action) {
            case "cancel":
              console.log("User clicked cancel/close button");
              break;
            case "picked":
              onFileUpload(
                `https://www.googleapis.com/drive/v3/files/${data.docs[0].id}?alt=media&key=AIzaSyAp8saWsv6oDcHpvM4LtEOz27I0eGjslbI`,
                data.docs[0].name
              );
              break;
            default:
          }
        },
      });
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<CloudDownloadIcon />}
        component="label"
        onClick={() => handleOpenPicker()}
      >
        Google Drive
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        key="top_center"
      >
        <Alert severity="error">Please sign in to google.</Alert>
      </Snackbar>
    </>
  );
};

export default GoogleDrivePicker;
