import React from "react";
import "./MDEditorProAction.less";
import { Fab } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MDEditorPro from "../MDEditorPro/MDEditorPro";

const MDEditorProAction = ({ value, onChange, label, onDelete, length }) => {
  return (
    <div className="cv__mdeditorproaction-flex">
      <MDEditorPro value={value} onChange={onChange} label={label} />
      <Fab
        disabled={length === 1}
        size="small"
        color="error"
        onClick={onDelete}
      >
        <DeleteIcon />
      </Fab>
    </div>
  );
};

export default MDEditorProAction;
