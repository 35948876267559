import React from "react";
import { Button, Fab, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { onFormChange } from "../../../redux/CVData";
import MDEditorPro from "../../../components/MDEditor/MDEditorPro/MDEditorPro";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import "../Editor.less";
import DeleteIcon from "@mui/icons-material/Delete";

const PersonalInfo = () => {
  const { p_info } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("p_info", name, value));
  };

  const handleMDChange = (name, value) => {
    dispatch(onFormChange("p_info", name, value));
  };

  const UploadAvatarButton = () => (
    <>
      {p_info.photo ? (
        <div className="cv__pinfo-photo">
          <div>
            <span>Profile Photo: </span>
            {p_info.photo[1]}
          </div>
          <Fab
            size="small"
            color="error"
            onClick={() => dispatch(onFormChange("p_info", "photo", ""))}
          >
            <DeleteIcon />
          </Fab>
        </div>
      ) : (
        <Button
          variant="contained"
          startIcon={<AccountBoxIcon />}
          component="label"
        >
          Upload Photo
          <input
            hidden
            type="file"
            accept="image/*"
            onChange={(e) =>
              dispatch(
                onFormChange("p_info", "photo", [
                  URL.createObjectURL(e.target.files[0]),
                  e.target.files[0].name,
                ])
              )
            }
          />
        </Button>
      )}
    </>
  );

  return (
    <div id="cv__pinfo">
      <UploadAvatarButton />
      <TextField
        label="Name"
        name="name"
        value={p_info.name}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />
      <TextField
        label="Job Title"
        name="job_title"
        value={p_info.job_title}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />
      <TextField
        label="Location"
        name="location"
        value={p_info.location}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />
      <TextField
        label="Phone Number"
        name="phone_num"
        value={p_info.phone_num}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />
      <MDEditorPro
        label="Email"
        value={p_info.email}
        onChange={(value) => handleMDChange("email", value)}
      />
      <MDEditorPro
        label="LinkedIn"
        value={p_info.linkedin}
        onChange={(value) => handleMDChange("linkedin", value)}
      />
      <MDEditorPro
        label="GitHub (If you have)"
        value={p_info.github}
        onChange={(value) => handleMDChange("github", value)}
      />
      <MDEditorPro
        label="Blog (If you have)"
        value={p_info.blog}
        onChange={(value) => handleMDChange("blog", value)}
      />
      <MDEditorPro
        label="Other Web Link"
        value={p_info.url}
        onChange={(value) => handleMDChange("url", value)}
      />
      <MDEditorPro
        label="Introduction / Summary / Profile"
        value={p_info.intro}
        onChange={(value) => handleMDChange("intro", value)}
      />
    </div>
  );
};

export default PersonalInfo;
