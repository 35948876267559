import React, { useState } from "react";
import "./TemplateCards.less";
import templateData from "../../data/TemplatesData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onTemplateChange } from "../../redux/CVData";
import TemplateDetail from "./TemplateDetail";

const TemplateCards = ({ isHome }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isZoomIn, setIsZoomIn] = useState(false);

  const [crtTemp, setCrtTemp] = useState({});

  const tempDataFiltered = isHome ? templateData.slice(0, 4) : templateData;

  const showDialog = (item) => {
    setCrtTemp(item);
    setIsZoomIn(true);
  };

  const toBuilderPage = (template) => {
    dispatch(onTemplateChange(template));
    navigate(`/builder/${template.id}/p_info`);
  };

  return (
    <>
      <div className="cv__templatecards">
        {tempDataFiltered.map((item) => (
          <div
            key={`cv__templatecards-${item.title}`}
            className="cv__templatecard"
          >
            <div
              className="cv__card-img cv__card-link"
              onClick={() => showDialog(item)}
            >
              <img src={item.img} alt="123" />
            </div>
            <div className="cv__card-title">{item.title}</div>
          </div>
        ))}
      </div>

      {/* Dialog - Template Detail */}
      <TemplateDetail
        isZoomIn={isZoomIn}
        setIsZoomIn={setIsZoomIn}
        crtTemp={crtTemp}
        toBuilderPage={toBuilderPage}
      />
    </>
  );
};

export default TemplateCards;
