import React, { useState } from "react";
import "./Sidebar.less";
import Preview from "../Preview/Preview";
import FooterMini from "../Footer/FooterMini";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sectionData from "../../data/SectionData";
import DashedButton from "../DashedButton/DashedButton";
import DialogPro from "../DialogPro/DialogPro";
import { useDispatch, useSelector } from "react-redux";
import { onFormItemEnable, onFormReplace } from "../../redux/CVData";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SectionList = () => {
  // Ensure that the checkbox remains on the original list after the page is refreshed.
  const { sectionId } = useParams();
  const currentIndex = sectionData.findIndex((item) => item.id === sectionId);
  const [selectedIndex, setSelectedIndex] = useState(currentIndex);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const handleListItemClick = (sectionId, index) => {
    setSelectedIndex(index);
    const lastSlashIndex = currentPath.lastIndexOf("/");
    const rootPath = currentPath.substring(0, lastSlashIndex);
    const targetPath = rootPath + "/" + sectionId;
    navigate(targetPath);
  };

  const cvData = useSelector((state) => state.cvData);
  // Create a new object to store the items to be displayed
  let filteredSections = sectionData.filter(
    (item) =>
      // Check if its 'enable' property is true or not present
      !cvData[item.id]?.hasOwnProperty("enable") || cvData[item.id].enable
  );

  return (
    <List className="cv__list cv__scrollbar" component="nav">
      {filteredSections.map((list, index) => (
        <ListItemButton
          key={`cv__list-item-btn-${list.id}`}
          selected={selectedIndex === index}
          onClick={() => handleListItemClick(list.id, index)}
          className="cv__list-item-btn"
        >
          <ListItemIcon>{list.icon}</ListItemIcon>
          <ListItemText primary={list.title} />
        </ListItemButton>
      ))}
    </List>
  );
};

const Section = ({ id: section, sectionData }) => {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: section,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
  };
  return (
    <div
      className="cv__sidebar-editseciton-flex"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <FormControlLabel
        control={
          <>
            <Checkbox
              checked={
                !sectionData.hasOwnProperty("enable") || sectionData.enable
              }
              disabled={!sectionData.hasOwnProperty("enable")}
              onChange={() => dispatch(onFormItemEnable(section))}
              name={sectionData.title}
            />
          </>
        }
        label={sectionData.title}
      />
      <DragIndicatorIcon className="cv__drag" {...listeners} />
    </div>
  );
};

const EditSection = () => {
  const dispatch = useDispatch();
  const cvData = useSelector((state) => state.cvData);
  const orders = cvData.orders;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const onDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      const oldIndex = orders.indexOf(active.id);
      const newIndex = orders.indexOf(over.id);
      dispatch(onFormReplace("orders", arrayMove(orders, oldIndex, newIndex)));
    }
  };
  return (
    <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked disabled />}
          label="Personal Info"
        />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={orders}
            strategy={verticalListSortingStrategy}
          >
            {orders.map((section, index) => (
              <Section
                key={`cv__sidebar-editsection-${index}`}
                id={section}
                sectionData={cvData[section]}
              />
            ))}
          </SortableContext>
        </DndContext>
      </FormGroup>
    </FormControl>
  );
};

const Sidebar = (props) => {
  const [isZoomIn, setIsZoomIn] = useState(false);

  return (
    <aside id="cv__sidebar">
      <SectionList />

      <DashedButton
        type="edit"
        name="Edit Section"
        onClick={() => setIsZoomIn(true)}
      />

      <Preview>{props.children}</Preview>

      <FooterMini />

      {/* Dialog - Edit Section */}
      <DialogPro
        className="cv__sidebar-editseciton"
        isShowDialog={isZoomIn}
        onClose={() => setIsZoomIn(false)}
      >
        <div className="cv__dialogpro-title">Edit Section</div>
        <EditSection />
      </DialogPro>
    </aside>
  );
};

export default Sidebar;
