import React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  addFormItemArray,
  deleteFormItem,
  deleteFormItemArray,
  onFormChange,
  onFormItemObjectChange,
  onFormItemChange,
} from "../../../redux/CVData";
import DashedButton from "../../../components/DashedButton/DashedButton";
import EditorAccordion from "../../../components/EditorAccordion/EditorAccordion";
import MDEditorProAction from "../../../components/MDEditor/MDEditorProAction/MDEditorProAction";

const ExpItemDesc = ({ itemIndex }) => {
  const { exp } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  return (
    <>
      {exp.item[itemIndex].desc.map((item, arrayIndex) => (
        <MDEditorProAction
          label="Experience Description"
          value={item}
          onChange={(value) =>
            dispatch(
              onFormItemObjectChange(
                "exp",
                itemIndex,
                arrayIndex,
                "desc",
                value
              )
            )
          }
          onDelete={() =>
            dispatch(deleteFormItemArray("exp", "desc", itemIndex, arrayIndex))
          }
          length={exp.item[itemIndex].desc.length}
          key={`cv__exp-item-desc-${arrayIndex + 1}`}
        />
      ))}
      <DashedButton
        type="add"
        name={"Add Experience Description"}
        onClick={() => dispatch(addFormItemArray("exp", "desc", itemIndex))}
      />
    </>
  );
};

const ExperienceItem = () => {
  const { exp } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormItemChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemChange("exp", index, name, value));
  };

  return (
    <>
      {exp.item.map((item, itemIndex) => (
        <EditorAccordion
          label={item.company ? item.company : `Experience ${itemIndex + 1}`}
          length={exp.item.length}
          onDelete={() => dispatch(deleteFormItem("exp", itemIndex))}
          key={`cv__exp-item-${itemIndex + 1}`}
        >
          <TextField
            label="Position"
            name="position"
            value={item.position}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />
          <TextField
            label="Company"
            name="company"
            value={item.company}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />
          <div className="cv__editor-flex MuiTextField-root">
            <TextField
              label="Start Date"
              name="start_date"
              value={item.start_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              label="End Date"
              name="end_date"
              value={item.end_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
          </div>
          <TextField
            label="Work Location"
            name="location"
            value={item.location}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />

          <ExpItemDesc itemIndex={itemIndex} />
        </EditorAccordion>
      ))}
      <DashedButton
        type="add"
        name={"Add Experience"}
        onClick={() => dispatch(addFormItem("exp"))}
      />
    </>
  );
};

const Experience = () => {
  const { exp } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("exp", name, value));
  };

  return (
    <div id="cv__experiences">
      <TextField
        label="Section Title"
        name="title"
        value={exp.title}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />

      <ExperienceItem />
    </div>
  );
};

export default Experience;
