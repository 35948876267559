import MDEditor, { commands } from "@uiw/react-md-editor";
import React from "react";
import "./MDEditorPro.less";

const MDEditorPro = ({ value, onChange, label }) => {
  return (
    <MDEditor
      id="cv__mdeditorpro"
      className="MuiTextField-root"
      value={value}
      onChange={onChange}
      commands={[
        {
          name: "Label",
          keyCommand: "Label",
          render: () => <p className="cv__mdeditorpro-label">{label}</p>,
        },
        commands.bold,
        commands.italic,
        commands.link,
      ]}
      extraCommands={[
        commands.codeEdit,
        commands.codeLive,
        commands.codePreview,
      ]}
      height={120}
    />
  );
};

export default MDEditorPro;
