import React from "react";
import "./Classic.less";
import { useDispatch, useSelector } from "react-redux";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import {
  LinkedIn,
  GitHub,
  MailOutline,
  Link,
  LocationOn,
  PhoneIphone,
  Article,
} from "@mui/icons-material";
import { onFormChange } from "../../redux/CVData";

const Classic = () => {
  const cvData = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const Section = ({ title, isShow, children }) => {
    return (
      (isShow === undefined || isShow) && (
        <div className="cvt__classic-section">
          <h2 className="cvt__classic-section-title cvt__themecolor">
            {title}
          </h2>
          {children}
        </div>
      )
    );
  };

  const ContactItem = ({ icon, data }) => {
    return (
      data && (
        <div>
          {icon}
          <ReactMarkdown components={{ p: "span" }}>{data}</ReactMarkdown>
        </div>
      )
    );
  };

  const PersonalInfo = () => {
    const data = cvData.p_info;
    const PersonalInfoWithoutPhoto = () => (
      <>
        <div className="cvt__classic-title">
          <h1 className="cvt__classic-name cvt__themecolor">{data.name}</h1>
          <h1 className="cvt__classic-jobtitle">{data.job_title}</h1>
        </div>
        <div className="cvt__classic-contact">
          <ContactItem icon={<LocationOn />} data={data.location} />
          <ContactItem icon={<PhoneIphone />} data={data.phone_num} />
          <ContactItem icon={<MailOutline />} data={data.email} />
          <ContactItem icon={<LinkedIn />} data={data.linkedin} />
          <ContactItem icon={<GitHub />} data={data.github} />
          <ContactItem icon={<Article />} data={data.blog} />
          <ContactItem icon={<Link />} data={data.url} />
        </div>
        <ReactMarkdown className="cvt__classic-intro">
          {data.intro}
        </ReactMarkdown>
      </>
    );
    return (
      <>
        {data.photo ? (
          <div className="cvt__classic-pinfo">
            <div>
              <PersonalInfoWithoutPhoto />
            </div>
            <img
              src={data.photo[0]}
              alt="Profile"
              onError={() => dispatch(onFormChange("p_info", "photo", ""))}
            />
          </div>
        ) : (
          <PersonalInfoWithoutPhoto />
        )}
      </>
    );
  };

  const Education = () => {
    return (
      <div className="cvt__classic-edu">
        {cvData.edu.item.map((item, index) => (
          <div
            className="cvt__classic-edu-item"
            key={`cvt__classic-edu-item-${index}`}
          >
            <div className="cvt__classic-edu-wrap cvt__subtitle">
              {item.school && (
                <p>
                  <b>{item.school}</b>
                </p>
              )}
              {(item.degree || item.major) && (
                <p>
                  {item.degree} in {item.major}
                </p>
              )}
              {(item.start_date || item.end_date) && (
                <p className="cvt__classic-date">
                  {item.start_date} - {item.end_date}
                </p>
              )}
              {item.location && (
                <p style={{ textDecoration: "underline" }}>{item.location}</p>
              )}
            </div>
            <ul>
              {item.desc.map(
                (item, idx) =>
                  item && (
                    <li key={`cvt__classic-education-li-${index}-${idx}`}>
                      <ReactMarkdown>{item}</ReactMarkdown>
                    </li>
                  )
              )}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const Skills = () => {
    return (
      <div className="cvt__classic-skills">
        {cvData.ski.item.map((item, index) => (
          <div
            className="cvt__classic-skills-item cvt__subtitle"
            key={`cvt__classic-skills-items-${index}`}
          >
            {item.ski_category && (
              <p className="cvt__classic-skills-cate">
                <b>{item.ski_category}</b>
              </p>
            )}
            <div className="cvt__classic-skills-skillset">
              {item.skills.map(
                (skill, idx) =>
                  skill && (
                    <span key={`cvt__classic-skills-item-${index}-${idx}`}>
                      {skill}
                    </span>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const Experience = () => {
    return (
      <div className="cvt__classic-experiences">
        {cvData.exp.item.map((item, index) => (
          <div
            className="cvt__classic-experiences-item"
            key={`cvt__classic-experiences-item-${index}`}
          >
            <div className="cvt__classic-experiences-wrap cvt__subtitle">
              <p>
                <b>{item.company}</b>
              </p>
              <p>
                <i>{item.position}</i>
              </p>
              <p>{item.location}</p>
              <p className="cvt__classic-date">
                {item.start_date} - {item.end_date}
              </p>
            </div>

            <ul>
              {item.desc.map(
                (item, idx) =>
                  item && (
                    <li key={`cvt__classic-experiences-li-${index}-${idx}`}>
                      <ReactMarkdown>{item}</ReactMarkdown>
                    </li>
                  )
              )}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const Projects = () => {
    return (
      <div className="cvt__classic-projects">
        {cvData.prj.item.map((item, index) => (
          <div
            className="cvt__classic-projects-item"
            key={`cvt__classic-projects-item-${index}`}
          >
            <div className="cvt__classic-projects-wrap cvt__subtitle">
              {item.prj_name && (
                <b>
                  <ReactMarkdown>{item.prj_name}</ReactMarkdown>
                </b>
              )}

              {item.skills && (
                <i>
                  <p>{item.skills}</p>
                </i>
              )}

              {(item.start_date || item.end_date) && (
                <p className="cvt__classic-date">
                  {item.start_date} - {item.end_date}
                </p>
              )}
            </div>

            <ul>
              {item.desc.map((item, idx) => (
                <li key={`cvt__classic-projects-li-${index}-${idx}`}>
                  <ReactMarkdown>{item}</ReactMarkdown>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const Certificates = () => {
    return (
      <div className="cvt__classic-certificates">
        {cvData.cer.item.map((item, index) => (
          <div key={`cvt__classic-certificate-${index}`}>
            <ReactMarkdown>{item}</ReactMarkdown>
          </div>
        ))}
      </div>
    );
  };

  const Hobbies = () => {
    return (
      <div className="cvt__classic-hobbies">
        {cvData.hb.item.map((item, index) => (
          <div
            className="cvt__classic-hobbies-item cvt__subtitle"
            key={`cvt__classic-hobbies-items-${index}`}
          >
            {item.hb_category && (
              <p>
                <b>{item.hb_category}</b>
              </p>
            )}
            <div className="cvt__classic-hobbies-items">
              {item.hobbies.map((hobby, idx) => (
                <p key={`cvt__classic-hobbies-item-${index}-${idx}`}>{hobby}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const sectionComponents = {
    edu: <Education />,
    ski: <Skills />,
    exp: <Experience />,
    prj: <Projects />,
    cer: <Certificates />,
    hb: <Hobbies />,
  };

  return (
    <div id="cvt__classic">
      <PersonalInfo />

      {cvData.orders.map((section) => (
        <Section
          title={cvData[section].title}
          isShow={cvData[section]?.enable}
          key={section}
        >
          {sectionComponents[section]}
        </Section>
      ))}
    </div>
  );
};

export default Classic;
