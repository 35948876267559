import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Home from "../pages/Home/Home";
import NotFound from "../pages/404/NotFound";
import Builder from "../layouts/Builder/Builder";
import templateData from "../data/TemplatesData";
import Start from "../pages/Start/Start";
import Templates from "../pages/Templates/Templates";
import ExportPDF from "../layouts/ExportPDF/ExportPDF";
import Privacy from "../pages/Privacy/Privacy";
import Auth from "../pages/Auth/Auth";
import Dashboard from "../pages/Dashboard/Dashboard";
import CVs from "../pages/Dashboard/CVs";
import Profile from "../pages/Dashboard/Profile";

const routes = [
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
  {
    path: "404",
    element: <NotFound />,
  },
  {
    path: "",
    element: <Home />,
  },
  {
    path: "start",
    element: <Start />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "start",
    element: <Start />,
  },
  {
    path: "templates",
    element: <Templates />,
  },
  {
    path: "auth",
    element: <Auth />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard/cvs" />,
      },
      { path: "cvs", element: <CVs /> },
      { path: "profile", element: <Profile /> },
    ],
  },
  {
    path: "builder",
    element: <Builder />,
    children: templateData.map((item) => ({
      path: `${item.template.id}/:sectionId`,
      element: item.element,
    })),
  },
  {
    path: "export-pdf",
    element: <ExportPDF />,
    children: templateData.map((item) => ({
      path: item.template.id,
      element: item.element,
    })),
  },
];

const Router = () => {
  const router = useRoutes(routes);
  return <> {router} </>;
};

export default Router;
