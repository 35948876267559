import React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  addFormItemArray,
  deleteFormItem,
  deleteFormItemArray,
  onFormChange,
  onFormItemObjectChange,
  onFormItemChange,
} from "../../../redux/CVData";
import DashedButton from "../../../components/DashedButton/DashedButton";
import EditorAccordion from "../../../components/EditorAccordion/EditorAccordion";
import MDEditorProAction from "../../../components/MDEditor/MDEditorProAction/MDEditorProAction";
import MDEditorPro from "../../../components/MDEditor/MDEditorPro/MDEditorPro";

const ProjectsItemDesc = ({ itemIndex }) => {
  const { prj } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  return (
    <>
      {prj.item[itemIndex].desc.map((item, arrayIndex) => (
        <MDEditorProAction
          label="Project Description"
          value={item}
          onChange={(value) =>
            dispatch(
              onFormItemObjectChange(
                "prj",
                itemIndex,
                arrayIndex,
                "desc",
                value
              )
            )
          }
          onDelete={() =>
            dispatch(deleteFormItemArray("prj", "desc", itemIndex, arrayIndex))
          }
          length={prj.item[itemIndex].desc.length}
          key={`cv__prj-item-desc-${arrayIndex + 1}`}
        />
      ))}
      <DashedButton
        type="add"
        name={"Add Project Description"}
        onClick={() => dispatch(addFormItemArray("prj", "desc", itemIndex))}
      />
    </>
  );
};

const ProjectsItem = () => {
  const { prj } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormItemChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemChange("prj", index, name, value));
  };

  const processPrjName = (name) => {
    name = name.trim();
    if (name.includes("](")) {
      return name.split("](")[0].slice(1);
    } else if (name.slice(0, 2) === "**" && name.slice(-2) === "**") {
      return name.slice(2, -2);
    } else if (name.slice(0, 1) === "*" && name.slice(-1) === "*") {
      return name.slice(1, -1);
    }
    return name;
  };

  return (
    <>
      {prj.item.map((item, itemIndex) => (
        <EditorAccordion
          label={
            item.prj_name
              ? processPrjName(item.prj_name)
              : `Project ${itemIndex + 1}`
          }
          length={prj.item.length}
          onDelete={() => dispatch(deleteFormItem("prj", itemIndex))}
          key={`cv__prj-item-${itemIndex + 1}`}
        >
          <MDEditorPro
            label="Project Name"
            value={item.prj_name}
            onChange={(value) =>
              dispatch(onFormItemChange("prj", itemIndex, "prj_name", value))
            }
          />

          <TextField
            label="Skills / Tech Stack"
            name="skills"
            value={item.skills}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />

          <div className="cv__editor-flex MuiTextField-root">
            <TextField
              label="Start Date"
              name="start_date"
              value={item.start_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              label="End Date"
              name="end_date"
              value={item.end_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
          </div>
          <ProjectsItemDesc itemIndex={itemIndex} />
        </EditorAccordion>
      ))}

      <DashedButton
        type="add"
        name={"Add Project"}
        onClick={() => dispatch(addFormItem("prj"))}
      />
    </>
  );
};

const Projects = () => {
  const { prj } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("prj", name, value));
  };

  return (
    <div id="cv__projects">
      <TextField
        label="Section Title"
        name="title"
        value={prj.title}
        onChange={handleChange}
        variant="outlined"
        autoComplete="off"
      />

      <ProjectsItem />
    </div>
  );
};

export default Projects;
