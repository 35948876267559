import React from "react";
import "./TextFieldAction.less";
import { Fab, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const TextFieldAction = ({
  label,
  name,
  value,
  length,
  onChange,
  onDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: value,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="cv__textfieldaction-flex"
    >
      <DragIndicatorIcon className="cv__drag" {...listeners} />
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        autoComplete="off"
      />
      <Fab
        disabled={length === 1}
        size="small"
        color="error"
        onClick={onDelete}
      >
        <DeleteIcon />
      </Fab>
    </div>
  );
};

export default TextFieldAction;
