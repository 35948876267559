import React, { useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import "./ChatGPT.less";
import DialogPro from "../DialogPro/DialogPro";
import SendIcon from "@mui/icons-material/Send";
import { Alert, CircularProgress, Fab, Snackbar } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import GppGoodIcon from "@mui/icons-material/GppGood";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import SmartFunc from "./SmartFunc";

const MessageList = ({ messages, setIsZoomIn }) => {
  return (
    <div
      className="cv__chatgpt-msgs cv__scrollbar"
      onClick={setIsZoomIn ? setIsZoomIn : () => {}}
    >
      {messages.map((msg, i) => (
        <div
          className="cv__chatgpt-msg animate__fadeIn animate__animated"
          key={`cv__chatgpt-msg-${i + 1}`}
        >
          {i % 2 === 0 ? (
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          ) : (
            msg.content
          )}
        </div>
      ))}
    </div>
  );
};

const MessageInput = ({ onSend, prompt, setPrompt, isTyped, isDisabled }) => {
  const textarea = document.querySelector(".cv__chatgpt-textarea");

  // Chat Input Box Height Dynamic Change
  const onResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleSend = () => {
    if (prompt !== "" && !isTyped) {
      onSend(prompt);
      setPrompt("");
      textarea.style.height = "auto";
    }
  };

  const onKeyDown = (e) => {
    // Determines if the Enter key is pressed (key code 13).
    if (e.key === "Enter" && !e.shiftKey) {
      // Block the default Enter key behaviour to prevent line breaks
      e.preventDefault();

      if (e.shiftKey) {
        setPrompt(prompt + "\n");
      } else {
        handleSend();
      }
    }
  };

  return (
    <div
      className={`cv__chatgpt-textarea-frame${isDisabled ? " disabled" : ""}`}
    >
      <textarea
        className="cv__chatgpt-textarea"
        placeholder={
          isDisabled ? "Please Input Your ChatGPT API Key" : "Ask for help..."
        }
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        onKeyDown={onKeyDown}
        onInput={onResize}
        rows="1"
        disabled={isDisabled}
      />
      <div
        onClick={handleSend}
        className={`cv__chatgpt-send${
          prompt === "" || isTyped ? "" : " active"
        }`}
      >
        <SendIcon />
      </div>
    </div>
  );
};

const SnackBarPro = ({ openSnackbar, setOpenSnackbar, alert }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackbar}
      onClose={() => setOpenSnackbar(false)}
      key="top_center"
      autoHideDuration={3000}
    >
      <Alert severity={alert.severity}>{alert.content}</Alert>
    </Snackbar>
  );
};

const ChatGPT = () => {
  const [apiKey, setAPIKey] = useState(
    JSON.parse(localStorage.getItem("api_key")) || ""
  );
  const [isShowAPIKey, setIsShowAPIKey] = useState(false);
  const [isZoomIn, setIsZoomIn] = useState(false);
  const [isTyped, setIsTyped] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alert, setAlert] = useState({
    severity: "success",
    content: "",
  });
  const [prompt, setPrompt] = useState("");
  const [messages, setMessages] = useState([
    {
      content: "Hi, I'm your Smart Assistant!",
      role: "assistant",
    },
  ]);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isGeneration, setIsGeneration] = useState(false);
  // Disable input box
  const [isDisabled, setIsDisabled] = useState(
    !JSON.parse(localStorage.getItem("api_key"))
  );

  // Send a message to ChatGPT
  const onSend = (prompt) => {
    const userMessage = {
      content: prompt,
      role: "user",
    };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);

    // set a typing indicator (chatgpt is typing)
    setIsTyped(true);
    // process message to ChatGPT (send it over and see the response)
    processMsgToChatGPT(newMessages);

    // When you send a msg, the msg box page will scroll to the bottom.
    setTimeout(() => {
      const msgs = document.querySelectorAll(".cv__chatgpt-msgs");
      msgs.forEach((element) => {
        element.scrollTop = element.scrollHeight;
      });
    }, 100);
  };

  const processMsgToChatGPT = (apiMessages) => {
    // The system message helps set the behavior of the assistant
    const systemMessage = {
      role: "system",
      content: "Act as a CV instructor, and help job seekers write good CV",
    };

    const apiRequestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [systemMessage, ...apiMessages],
    });

    axios
      .post("https://api.openai.com/v1/chat/completions", apiRequestBody, {
        headers: {
          Authorization: "Bearer " + apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const ChatGPT_Message = {
          content: res.data.choices[0].message.content,
          role: "assistant",
        };
        const newMessages = [...apiMessages, ChatGPT_Message];
        setMessages(newMessages);
        setIsTyped(false);

        if (isGeneration) {
          setIsGeneration(false);
          // dispatch(onFormGeneration(extractObject(ChatGPT_Message.content)));
        }
      })
      .catch((err) => {
        setIsTyped(false);
        console.error(err);
      });
  };

  // Save your api key
  const onSaveAPIKey = () => {
    const apiKeyStr = JSON.stringify(apiKey);
    localStorage.setItem("api_key", apiKeyStr);
    setAlert({
      severity: "success",
      content: "Your api key has been successfully saved to your local browser",
    });
    setIsDisabled(false);
    setOpenSnackbar(true);
  };

  // Delete your api key
  const onDeleteAPIKey = () => {
    localStorage.removeItem("api_key");
    setAlert({
      severity: "success",
      content:
        "Your api key has been successfully removed from your local browser",
    });
    setAPIKey("");
    setIsDisabled(true);
    setOpenSnackbar(true);
  };

  // Close api key Dialog
  const onCloseAPIKey = () => {
    if (JSON.parse(localStorage.getItem("api_key"))) {
      setAPIKey(JSON.parse(localStorage.getItem("api_key")));
    } else {
      setAPIKey("");
    }
    setIsShowAPIKey(false);
  };

  // Extract object from the string returned by ChatGPT
  const extractObject = (string) => {
    const objectRegex = /\{([^{}]+)\}/;
    const matches = string.match(objectRegex);

    if (matches && matches.length > 0) {
      try {
        const extractedObject = eval(`(${matches[0]})`);
        return extractedObject;
      } catch (error) {
        console.error("Error parsing object:", error);
      }
    }

    return null;
  };

  const scrollToBottom = () => {
    const msgs = document.querySelectorAll(".cv__chatgpt-msgs");
    msgs.forEach((element) => {
      element.scrollTop = element.scrollHeight;
    });
  };

  return (
    <>
      <div id="cv__chatgpt" className="cv__card">
        <div className="cv__chatgpt-header">
          <div className="cv__chatgpt-title">
            Smart Assistant
            {isTyped && (
              <div className="cv__chatgpt-status">
                <CircularProgress size={20} thickness={5} />
              </div>
            )}
          </div>

          <div className="cv__chatgpt-icons">
            <div
              className={`${
                JSON.parse(localStorage.getItem("api_key")) ? "active" : ""
              }`}
              onClick={() => setIsShowAPIKey(true)}
            >
              <KeyIcon />
            </div>
            <div onClick={() => setIsShowInfo(true)}>
              <HelpIcon />
            </div>
          </div>
        </div>

        <SmartFunc
          onSend={onSend}
          setIsGeneration={setIsGeneration}
          hasAPI={!!apiKey}
        />

        <MessageList
          messages={messages}
          setIsZoomIn={() => setIsZoomIn(true)}
        />
        <MessageInput
          onSend={onSend}
          prompt={prompt}
          setPrompt={setPrompt}
          isTyped={isTyped}
          isDisabled={isDisabled}
        />

        <SmartToyIcon className="cv__chatgpt-bg" />
      </div>

      {/* Dialog - ZoomIn Dialog */}
      <DialogPro
        className="cv__dialogpro-large"
        isShowDialog={isZoomIn}
        onClose={() => setIsZoomIn(false)}
        extraFunc={scrollToBottom}
      >
        <div className="cv__dialogpro-title">
          Smart Assistant
          {isTyped && (
            <div className="cv__chatgpt-status">
              <CircularProgress size={20} thickness={5} />
            </div>
          )}
        </div>

        <MessageList messages={messages} />
        <MessageInput
          onSend={onSend}
          prompt={prompt}
          setPrompt={setPrompt}
          isTyped={isTyped}
          isDisabled={isDisabled}
        />
      </DialogPro>

      {/* Dialog - API Key Dialog */}
      <DialogPro
        className="cv__dialogpro-apikey"
        isShowDialog={isShowAPIKey}
        onClose={onCloseAPIKey}
      >
        <div className="cv__dialogpro-title">Your ChatGPT API Key</div>

        <div className="cv__chatgpt-input-frame">
          <input
            className="cv__chatgpt-input"
            value={apiKey}
            onChange={(e) => setAPIKey(e.target.value)}
          />
          <Fab
            size="small"
            color="primary"
            disabled={!apiKey}
            onClick={onSaveAPIKey}
          >
            <SaveIcon />
          </Fab>
          <Fab
            size="small"
            color="error"
            disabled={!JSON.parse(localStorage.getItem("api_key"))}
            onClick={onDeleteAPIKey}
          >
            <DeleteIcon />
          </Fab>
        </div>
        <Alert
          variant="outlined"
          icon={<GppGoodIcon fontSize="inherit" />}
          severity="success"
          sx={{ marginBottom: "20px" }}
        >
          Your API key is safe with us! It will be stored securely on your local
          browser. We prioritize your security and privacy.
        </Alert>
        <Link
          className="cv__link-question"
          target="_blank"
          rel="noreferrer"
          to="https://platform.openai.com/account/api-keys"
        >
          <HelpIcon sx={{ fontSize: "16px" }} />
          <p>Where to get my ChatGPT API key?</p>
        </Link>
      </DialogPro>

      {/* Dialog - Info of Funcs */}
      <DialogPro
        className="cv__dialogpro-info"
        isShowDialog={isShowInfo}
        onClose={() => setIsShowInfo(false)}
      >
        <div className="cv__dialogpro-title">
          Information of Smart Assistance
        </div>
        <ol>
          <li>
            Your CV data will be uploaded to ChatGPT for analysis. Although
            ChatGPT is relatively reliable, this website will anonymize the
            personal information in your CV. Specifically, the personal
            information fields such as name, location, phone number, email, and
            URL will be set to blank.
          </li>
          <li>
            <b>Generate Your CV</b>: This feature will automatically generate
            content for your CV based on your chosen job position and selected
            CV sections.{" "}
            <b>
              Please note that the CV content generated by this function will
              overwrite the content you've already filled in.
            </b>
          </li>
          <li>
            <b>Inspire CV Ideas</b>: This feature provides you with inspiration
            and examples for writing your CV based on the CV section you're
            currently editing and the content you've already filled in.
          </li>
          <li>
            <b>Evaluate & Optimize</b>: This feature evaluates all the content
            in your CV and provides optimization suggestions.
          </li>
        </ol>
      </DialogPro>

      <SnackBarPro
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        alert={alert}
      />
    </>
  );
};

export default ChatGPT;
