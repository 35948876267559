import React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  addFormItemArray,
  deleteFormItem,
  deleteFormItemArray,
  onFormChange,
  onFormItemObjectChange,
  onFormItemChange,
} from "../../../redux/CVData";
import EditorAccordion from "../../../components/EditorAccordion/EditorAccordion";
import DashedButton from "../../../components/DashedButton/DashedButton";
import MDEditorProAction from "../../../components/MDEditor/MDEditorProAction/MDEditorProAction";

const EduItemDesc = ({ itemIndex }) => {
  const { edu } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  return (
    <>
      {edu.item[itemIndex].desc.map((item, arrayIndex) => (
        <MDEditorProAction
          label="Education Description"
          value={item}
          onChange={(value) =>
            dispatch(
              onFormItemObjectChange(
                "edu",
                itemIndex,
                arrayIndex,
                "desc",
                value
              )
            )
          }
          onDelete={() =>
            dispatch(deleteFormItemArray("edu", "desc", itemIndex, arrayIndex))
          }
          length={edu.item[itemIndex].desc.length}
          key={`cv__edu-item-desc-${arrayIndex + 1}`}
        />
      ))}
      <DashedButton
        type="add"
        name={"Add Education Description"}
        onClick={() => dispatch(addFormItemArray("edu", "desc", itemIndex))}
      />
    </>
  );
};

const EduItem = ({ edu }) => {
  const dispatch = useDispatch();
  const handleFormItemChange = (index, event) => {
    const { name, value } = event.target;
    dispatch(onFormItemChange("edu", index, name, value));
  };

  return (
    <>
      {edu.item.map((item, itemIndex) => (
        <EditorAccordion
          label={item.school ? item.school : `Institution ${itemIndex + 1}`}
          length={edu.item.length}
          onDelete={() => dispatch(deleteFormItem("edu", itemIndex))}
          key={`cv__edu-item-${itemIndex + 1}`}
        >
          <TextField
            label="Institution Name"
            name="school"
            value={item.school}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />
          <TextField
            label="Degree"
            name="degree"
            value={item.degree}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />
          <TextField
            label="Field Of Study"
            name="major"
            value={item.major}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />
          <div className="cv__editor-flex MuiTextField-root">
            <TextField
              label="Start Date"
              name="start_date"
              value={item.start_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              label="End Date"
              name="end_date"
              value={item.end_date}
              onChange={(e) => handleFormItemChange(itemIndex, e)}
              variant="outlined"
              autoComplete="off"
            />
          </div>
          <TextField
            label="Institution Location"
            name="location"
            value={item.location}
            onChange={(e) => handleFormItemChange(itemIndex, e)}
            variant="outlined"
            autoComplete="off"
          />

          <EduItemDesc itemIndex={itemIndex} />
        </EditorAccordion>
      ))}
      <DashedButton
        type="add"
        name={"Add Institution"}
        onClick={() => dispatch(addFormItem("edu"))}
      />
    </>
  );
};

const Education = () => {
  const { edu } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("edu", name, value));
  };

  return (
    <div id="cv__education">
      <TextField
        label="Section Title"
        name="title"
        value={edu.title}
        onChange={handleFormChange}
        variant="outlined"
        autoComplete="off"
      />

      <EduItem edu={edu} />
    </div>
  );
};

export default Education;
