import React from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFormItem,
  deleteFormItem,
  onFormChange,
  onFormItemArrayChange,
} from "../../../redux/CVData";
import DashedButton from "../../../components/DashedButton/DashedButton";
import MDEditorProAction from "../../../components/MDEditor/MDEditorProAction/MDEditorProAction";

const CertificateItems = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <>
      {data.item.map((item, index) => (
        <MDEditorProAction
          label="Certificate Name"
          value={item}
          onChange={(value) =>
            dispatch(onFormItemArrayChange("cer", index, value))
          }
          onDelete={() => dispatch(deleteFormItem("cer", index))}
          length={data.item.length}
          key={`cv__cer-item-${index}`}
        />
      ))}
      <DashedButton
        type="add"
        name={"Add A Certificate"}
        onClick={() => dispatch(addFormItem("cer"))}
      />
    </>
  );
};

const Certificates = () => {
  const { cer } = useSelector((state) => state.cvData);
  const dispatch = useDispatch();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    dispatch(onFormChange("cer", name, value));
  };

  return (
    <div id="cv__cer">
      <TextField
        label="Section Title"
        name="title"
        value={cer.title}
        onChange={handleFormChange}
        variant="outlined"
        autoComplete="off"
      />

      <CertificateItems data={cer} />
    </div>
  );
};

export default Certificates;
