import React from "react";
import "./NotFound.less";
import svg404 from "../../assets/404.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div id="cv__404">
      <img src={svg404} alt="404 svg" />
      <Button
        variant="contained"
        onClick={() => navigate("/")}
      >
        Back to Home
      </Button>
    </div>
  );
};

export default NotFound;
