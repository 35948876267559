import React, { useState } from "react";
import "./PopupMenu.less";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PopupMenu = ({ children, menuData, className }) => {
  const [isShowList, setIsShowList] = useState(false);
  const [isHiding, setIsHiding] = useState(false);

  // Close dialog box (with animation)
  const onMouseLeave = () => {
    setIsHiding(true);
    const timer = setTimeout(() => {
      setIsShowList(false);
      setIsHiding(false);
      clearTimeout(timer);
    }, 300);
  };

  return (
    <div
      id="cv__popupmenu"
      className={className}
      onMouseEnter={() => setIsShowList(true)}
      onMouseLeave={onMouseLeave}
    >
      <div className="cv__popupmenu-flex">
        {children}
        <KeyboardArrowDownIcon />
      </div>
      {isShowList && (
        <ul
          className={`cv__popupmenu-list cv__card${isHiding ? "" : " zoomIn"}`}
        >
          {menuData.map((item, index) => (
            <li key={`cv__popupmenu-list-${index}`}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PopupMenu;
